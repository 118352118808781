<div class="not-authorized"  [ngClass]="!isNotAdmin ? 'notAdmin' : 'isAdmin'">
  <p class="qad-title">QAD Nomination Tool: Not Authorized Warning</p>
  <div class="body">
    <p>
      You are not authorized to view the page or form you requested in the QAD
      Nomination Tool.
    </p>
    <p>To view the QAD Nomination Tool, a user must be:</p>
    <ul>
      <li>A nominee assigned to a form OR</li>
      <li>
        Assigned an administrative or organizational role within Accenture
      </li>
    </ul>
    <br />
    <p>
      To obtain access to a specific page or form, please contact Quality
      Support as
      <a [href]="'mailto:'+ QADqualityLink + '?subject= QAD Nomination Help'" target="_blank">{{ QADqualityLink }}</a>.
    </p>
    <ul>
      <li>
        For additional information on role access, reference the
        <a href={{QADportalLink}}
          target="_blank">Quality Assurance Director Organizational Level Access Request job
          aid</a
        >.
      </li>
      <br />
    </ul>
    <p>
      To request organizational level access via <a href="https://support.accenture.com/support_portal?id=my_access#request_access">myAccess</a>,
      reference the
      <a href={{QADportalLink}}
        target="_blank">Quality Assurance Director Organizational Level Access Request job
        aid</a
      >.
    </p>
    <br />
    <p>
      If you have access to other QAD Nomination forms or pages,
      <a href={{applicationurl}}
        >click to return to the QAD Nomination Tool home page</a
      >.
    </p>
    <br />
    <p>
      If you believe you already have access and are receiving this message in
      error, please contact Quality Support at
      <a [href]="'mailto:'+ QADqualityLink +'?subject= QAD Nomination Help'" target="_blank">{{ QADqualityLink }}</a>.
    </p>
  </div>
</div>
