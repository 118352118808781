import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ViewChildren, QueryList, ViewEncapsulation, Renderer2, Injectable, HostListener, Directive } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeModule, } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { async, expand, forkJoin, Observable, Subject, Subscription, takeUntil, takeWhile, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IRoleLevelAccess } from '../../../core/models/RoleLevelAccess';
import { FormStatusTimeline, getNominationForm } from '../../../core/models/NominationFormData';
import { json } from 'stream/consumers';
import { any } from 'cypress/types/bluebird';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MsalService } from '@azure/msal-angular';
import { AppTimeoutService } from '../../../core/services/AppTimeoutService';

//let result: SkillsNode[] = [];
interface SkillsTreeNode {
  disableSecSkillsFlag: any;
  indexPosition: number;
  id: number;
  skillsExpertiseNm: string;
  isChecked: boolean;
  indeterminate?: boolean;
  children: SkillsTreeNode[];
  parent?: any;
  expanded?: boolean;
  expertiseSkillId: number;
  // isHidden:boolean;

}
interface SkillsCheckedNode {
  id: number;
  skillsExpertiseNm: string;
}

interface SteercoDecisions {
  SCDecisionCd: number;
  SCDecisionDesc: string;
}
interface SupportSection {
  SupportDesc: string;
  SupportLinkDesc: string;
}

export class CustomDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return moment(date).format('DD-MMM-YYYY');
    }
    return date.toDateString();
  }
}


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-qadnomination-form',
  templateUrl: './qadnomination-form.component.html',
  styleUrls: ['./qadnomination-form.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en' }, // Replace 'en' with your desired locale
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },

  ]
})

export class QadnominationFormComponent implements OnInit {
  supportSectionValues: any;
  isContractor: boolean = false;
  currentusername: any = '';
  isAdmin = false;
  isNominee = false;
  isReviewer = false;
  isReadOnly = false;
  userRole!: IRoleLevelAccess;
  FormID: number = 0;
  defaultDate: Date = new Date('1900-01-01');
  FormSkillsIds$!: Observable<any>;
  NomintionFormData$!: Observable<any>;
  FormStatusTimeline: FormStatusTimeline[] = [];
  AOEData: any[] = [];
  SkillsTree_Data: SkillsTreeNode[] = [];
  drpdwnStreeCoDecisions: SteercoDecisions[] = [{ 'SCDecisionCd': 1, 'SCDecisionDesc': 'Ready for Certification' }, { 'SCDecisionCd': 2, 'SCDecisionDesc': 'Cancel' }, { 'SCDecisionCd': 3, 'SCDecisionDesc': 'On Hold' }]
  NominationFormData: getNominationForm[] = [];
  isDataLoaded: boolean = false;
  selectedAOEIds: string = '';
  selectedSkillsTreeNode: SkillsTreeNode | null = null;
  submitbtntooltip: string = "";
  savebtntooltip: string = "";
  noUpdatesneededbtntooltip: string = "";
  approvebtntooltip: string = "";
  NomimateEQADbtntooltip: string = "";
  btnapprovetooltipClass: string = "";
  btnnopudatestooltipClass: string = "";
  tooltipflag: boolean = false;
  FormStatus: string = '';
  StreeCoDecisionsselected: number = 0;
  Streecocontroldisabled: boolean = false;
  datepickerdiabled: string = '';
  SkillsClickicon = 'assets/img/NominationForm/add_circle_outline_purple.svg';
  // SkillsdialogOpenClick = 'assets/img/NominationForm/add_circle_open.svg';
  displayStyle = "none";
  //formstatus: string = 'Draft';
  QADFormFlag: boolean = false;
  EliteQADFormFlag: boolean = false;
  QADFormSubmittedFlag: boolean = false;
  QLApprovedFlag: boolean = false;
  QADCredentialingFlag: boolean = false;
  QADCerfiedFlag: boolean = false;
  EQADFormSubmittedFlag: boolean = false;
  EQADQLApprovedFlag: boolean = false;
  EQADCredentialingFlag: boolean = false;
  EQADCertifiedFlag: boolean = false;
  isTreeNodeToggleRecursive: boolean = true;
  treeData: any
  //userRole?: IRoleLevelAccess[];
  skillsCheckedNodes: SkillsCheckedNode[] = [];
  NomineeEId: any;
  //AOEandSkillsReadonlyFlag: boolean = true;
  rationaleforNominationtxt: string = '';
  steercoNotes: string = '';
  SCReviewDate: any;
  SCReconsiderDate: any;
  RegistrationCanceledDt: any;
  ReactivateDttm: any;
  RegistrationCanceledDtBottomDisplay:any;
  TrainingCompletionDt: any;
  MasteryTestCompletionDt: any;
  CaseStudyAssessmentCompletionDt: any;
  tempSkillSearch: string = "";
  @ViewChildren('datePickers') datePickers!: QueryList<any>;
  isLoading: boolean = false;
  invalidURLtxt: string = '';
  skillstreeControl = new NestedTreeControl<SkillsTreeNode>(node => node.children);
  skillsdataSource = new MatTreeNestedDataSource<SkillsTreeNode>();
  public skillsSearchString = '';
  public previousSearchString = '';
  public showOnlySelected = false;
  public checklist: string[] = [];
  public results: SkillsTreeNode[] = [];
  checkedNodes: SkillsTreeNode[] = [];
  modalRef: NgbModalRef | undefined;
  private isModalOpen = false;
  previousFormStatus: any;
  disablesteercoDateandDecison: boolean = false;
  registerationCanceldtdisable: boolean = false;
  public NominatorEIDData: any = "";
  StreeCoDecisionDesc: any = "";
  previousSteercoDecision!: number;
  NominatorpeopleKey: any = "";
  NominatorEID: any = "";
  nominatorflagEID: any = "";
  AOEflag: any = "";
  SkillsFlag: any = "";
  noupdatesbtnvisiblecheck: boolean = false;
  qadRationaleText: any = "";
  EQADNominateFlag: boolean = false;
  skillscheckboxStates: { [key: string]: boolean } = {};
  public NominatorsMarketToolTip: any = "";
  NominatorEIDs: any[] = [];
  NominatorsEnterprise: string = "";
  NominatorData: any;
  nominatortext: any = "";
  InValidNominatorEID: any;
  public lblNominatorMarket: any = "";
  public NominatorPeopleKey: any = "";
  public lblWarning: any = "";
  i!: number;
  isAutocompleteOpen = false;
  private readonly checkInterval = 1000; // Check every second
  private destroy$ = new Subject<void>();
  public prevLastNom: any="";
  private readonly INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 hour in milliseconds
  private readonly ACTIVITY_CHECK_INTERVAL = 5000; // 5 seconds in milliseconds
  previousAOEIds: string = '';
  previousSkillsIds: string = '';
  NominatorCtrl = new FormControl();
  private logoutKey = 'userLogout';
  QADskillExp: any;
    constructor(private rebarAuthService: RebarAuthService, private commonService: CommonService, private _formBuilder: FormBuilder, public dialog: MatDialog, private modalService: NgbModal, private http: HttpClient, private route: ActivatedRoute, private router: Router, private cdRef: ChangeDetectorRef, private el: ElementRef, private renderer: Renderer2, private auth: MsalService, private apptimeoutService: AppTimeoutService) {
    this.resetapplicationTimer();
    this.startapplicationActivityCheck();
  }
  ngOnInit(): void {
    this.setupStorageListener();
    this.startTokenCheck();
    this.rebarAuthService.refreshToken();
    this.currentusername = this.commonService.getCurrentUsername();
    this.route.paramMap.subscribe(params => {
      this.NomineeEId = params.get('enterpriseid');
    });
    const EnterpriseId = this.NomineeEId;
    const skillsparams = { View: 0, EnterpriseId, AdminUser: this.currentusername, EID: this.currentusername };
    this.isLoading = true;
    this.getCurrentUserRole();
    this.commonService.getSkills(skillsparams).subscribe(
      (response) => {
        this.SkillsTree_Data = response;
        this.skillsdataSource.data = this.SkillsTree_Data;
        Object.keys(this.skillsdataSource.data).forEach((key: any) => {
          this.setParent(this.skillsdataSource.data[key]);
        });

        const nomineeEidparams = { NomineeEnterpriseId: this.NomineeEId, AdminUser: this.currentusername };

        this.NomintionFormData$ = this.commonService.getNominationFormData(nomineeEidparams);
      
        forkJoin([this.NomintionFormData$, this.commonService.getSkills(skillsparams)]).subscribe(([nominationFormData, skillsData]) => {
          this.getSelectedSkills();
        });
      });

    this.getNominationFormData();
    this.NominationFormTimeline();
    this.AreaofExpertise();
    this.getSupportSectionDetails();



  }
  getSupportSectionDetails() {
    this.commonService.getHeaderSupportSection().subscribe((response) => {

      if (response.length > 0) {
        this.supportSectionValues = response;
        this.QADskillExp = this.supportSectionValues.filter((x: SupportSection) => x.SupportDesc === 'QAD Skills Expertise').map((x: SupportSection) => x.SupportLinkDesc);      
      }
    });
  }
  async getCurrentUserRole() {
    const paramEID = { EID: this.currentusername };
    await this.commonService.getCurrentUserRole(paramEID).subscribe((response: any) => {
      this.userRole = response.find((item: any) => item.EnterpriseId === this.currentusername);
      //console.log("Filtered User Role:", this.userRole); // Debugging
      if (this.userRole) {
        const { RoleDesc } = this.userRole;
        if (RoleDesc === 'Administrator') {
          this.isAdmin = true;
        } else if (RoleDesc === 'Nominee') {
          this.isNominee = true;
        } else if (RoleDesc === 'Reviewer') {
          this.isReviewer = true;
        }
        else if (RoleDesc === 'ReadOnly') {
          this.isReadOnly = true;
        }
        // console.log("User Role Set:", RoleDesc);
        // console.log("isAdmin:", this.isAdmin, "isReviewer:", this.isReviewer, "isNominee:", this.isNominee);
      }
      //else {
      //  this.router.navigate(['/not-authorized']);
      //}
    });
  }
  prevSelectedNominatorsEIDArr: string[] = [];
  prevSelectedNominatorsEIDstr: string = "";
  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.prevSelectedNominatorsEIDArr.length > 0 && this.NominatorEIDs.length == 1) {
      const lastValueOfNominatorEIDs = this.prevSelectedNominatorsEIDArr[this.prevSelectedNominatorsEIDArr.length - 1];
      const isLastValuePresentInnameArr = this.NominatorEIDs.some(value => value === lastValueOfNominatorEIDs);

      if (isLastValuePresentInnameArr) {
        this.NominatorEIDs = this.prevSelectedNominatorsEIDArr.map((value: any) => value.toString());
      }
    }

    if ((this.NominatorEIDs).includes(event.option.viewValue) == false) {
      this.NominatorEIDs.push(event.option.viewValue);
      this.NominatorsEnterprise = (this.NominatorEIDs).join(',');
    } 
    this.NominatorEIDs = Array.from(new Set(this.NominatorEIDs.map(e => e.trim()))); //Remove duplicate values
    this.prevSelectedNominatorsEIDArr = this.NominatorEIDs;
    this.prevSelectedNominatorsEIDstr = (this.NominatorEIDs).join(',');
    this.NominatorCtrl.setValue(this.prevSelectedNominatorsEIDstr);
    this.NominatorsEnterprise = (this.NominatorEIDs).join(',');
  }
  
  async onFocusOutNominatorEvent(event: any) {
    const savebtn = document.getElementById('btnSave') as HTMLButtonElement;
    const lblFormWarning = document.getElementById('lblformwarning') as HTMLLabelElement;
    const txtboxnominator = document.getElementById('NominatorpeopleEID') as HTMLTextAreaElement;
    let nominatorid = event.target.value; 
    nominatorid = nominatorid.replace(/,*$/, '');
    if (event.target.value != "") {
      const nominatorEID = { NominatorEnterpriseId: nominatorid, EID : this.currentusername };
      await this.commonService.getNominatorDetails(nominatorEID).subscribe((response: any) => {
        this.NominatorData = response;
        this.NominatorpeopleKey = this.NominatorData[0].NominatorPeopleKey;
        this.NominatorEID = this.NominatorData[0].NominatorEID;
        this.InValidNominatorEID = this.NominatorData[0].NominatorInvalidEID;
        if (this.InValidNominatorEID == null) {
          this.lblNominatorMarket = this.NominatorData[0].NominatorMarket;
          if ((this.lblNominatorMarket).length > 35) {
            this.NominatorsMarketToolTip = this.lblNominatorMarket;
          }
          else {
            this.NominatorsMarketToolTip = "";
          }
          this.NominatorPeopleKey = this.NominatorData[0].NominatorPeopleKey;
        } 
        else {this.lblNominatorMarket = "";
        savebtn.disabled = true;
      }

      });
    }
    //this.NominatorCtrl.setValue(Array.from(new Set(nominatorid)));
    this.NominatorsEnterprise = nominatorid;
  }
  onFocusinNominator(event: any): void{
      var overlayElement = document.querySelector('.cdk-overlay-pane');
    
  }

  async NominatorChangeFn(event: any) {
    const lastVal = this.NominatorsEnterprise.slice(-1);
    const lblFormWarning = document.getElementById('lblformwarning') as HTMLLabelElement;
    var namedArr = this.NominatorsEnterprise.split(',');
    let val = "";
    const currentLastNom = namedArr[(namedArr.length) - 1];
    //const hidePop = document.getElementById('cdk-overlay-0') as HTMLDivElement;
    
    if (this.NominatorEIDs.length > 0) {
      namedArr = this.NominatorEIDs;
      if (namedArr.indexOf(currentLastNom) >= 0) { val = "true" }
    }
    if (lastVal == ',' || currentLastNom == this.prevLastNom || val == "true") {
      const hidePop = document.querySelector('.cdk-overlay-pane');
      hidePop?.classList.add('removePopup');
      hidePop?.classList.remove('cdk-overlay-pane');
      this.NominatorEIDData = [];
      const nominator = { NominatorEnterpriseId: this.NominatorsEnterprise, EID: this.currentusername };
      await this.commonService.getNominatorDetails(nominator).subscribe((response: any) => {
        this.NominatorData = response;
        this.InValidNominatorEID = this.NominatorData[0].NominatorInvalidEID;
        if (this.InValidNominatorEID == null) {
          lblFormWarning.style.display = 'none';
          lblFormWarning.style.marginTop = '0px';
        }
      });
    }
    else {
      const savebtn = document.getElementById('btnSave') as HTMLButtonElement;
      lblFormWarning.style.display = 'none';  
      if (this.NominatorsEnterprise == null || this.NominatorsEnterprise == "") {
        this.lblNominatorMarket = "";
        this.NominatorPeopleKey = "";
        savebtn.disabled = true;
        lblFormWarning.style.display = 'none';
      }
      else {
        lblFormWarning.style.display = 'none';
        lblFormWarning.style.marginTop = '0px';
        let nominatoreid = this.NominatorsEnterprise;
        nominatoreid = nominatoreid.replace(/,*$/, '');
        var nameArr = nominatoreid.split(',');
        const length = nameArr.length;
        this.prevLastNom = nameArr[length - 1];
        if (length > 1) {
          this.nominatortext = { Eid: nameArr[length - 1], CurrentEID: this.currentusername };
        }
        else {
          this.nominatortext = { Eid: nominatoreid, CurrentEID: this.currentusername };
        }
        await this.commonService.getNominatorEID(this.nominatortext).subscribe((response: any) => {
          this.NominatorEIDData = response;
        });
        const hidePops = document.querySelector('.removePopup');
        hidePops?.classList.add('cdk-overlay-pane');
        hidePops?.classList.remove('removePopup');
        if (event.type != "click") {
          const nominator = { NominatorEnterpriseId: nominatoreid, EID: this.currentusername };
          await this.commonService.getNominatorDetails(nominator).subscribe((response: any) => {
            this.NominatorData = response;
            this.InValidNominatorEID = this.NominatorData[0].NominatorInvalidEID;
            if (this.NominatorsEnterprise == "" || this.NominatorsEnterprise === null || this.InValidNominatorEID === null) {
              lblFormWarning.style.display = 'none';
              this.lblWarning = " ";

              //this.lblNominatorMarket = '';
            }
            else if (this.InValidNominatorEID != null) {
              const invalidnom = this.InValidNominatorEID.split(',');
              for (this.i = 0; this.i < invalidnom.length; this.i++) {
                const ind = nameArr.indexOf(invalidnom[this.i]);
                if (ind > -1) {
                  nameArr.splice(ind, 1);
                }
              }
              lblFormWarning.style.display = 'block';
              this.lblWarning = "EID is not valid.";
              savebtn.disabled = true;
            }
            this.NominatorEIDs = nameArr.map((value: any) => value.toString());
          });
        }
      }
     }


   }

  datePickerYear() {
    
    var minDate = moment([0, 0, 1]);
    
    return  minDate;
  }

  restrictSpecialChar(event: any) {
    var k = event.which || event.keyCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 44 || k === 46 || k === 8) {
      return true;
    }
    else {
      return false;
    }
  }

  nominatortooltipStyle() {
    const nominatortooltipElements = document.querySelectorAll('.mdc-tooltip__surface');

    if (nominatortooltipElements) {

      nominatortooltipElements.forEach((nominatortooltipElement) => {
        if (nominatortooltipElement.classList.contains('mdc-tooltip__surface')) {
          nominatortooltipElement.classList.add('nominatortooltipstyle');
        }
      });
    }
  }
isSavesbmitbtnEnabled() {
  let savesubmitbtndisabled = false;
  this.savebtntooltip = '';
  this.tooltipflag = false;
  this.submitbtntooltip = '';
  if ((this.isAdmin || this.isReviewer || this.isNominee) && (this.isDraft && this.EQADNominateFlag))   {

    if (this.NominatorsEnterprise == "" || this.NominatorsEnterprise === null) {
      savesubmitbtndisabled = true;
      this.tooltipflag = true;
      this.nominatortooltipStyle();
      this.savebtntooltip = 'Nominators Enterprise ID is required';
       this.submitbtntooltip = 'Nominators Enterprise ID is required';
     // this.noUpdatesneededbtntooltip = "Nominators Enterprise ID is required";
    //  this.btnnopudatestooltipClass = '';
    }
    else if (this.InValidNominatorEID != null) {
      savesubmitbtndisabled = true;
      this.tooltipflag = true;
      this.nominatortooltipStyle();
      this.savebtntooltip = 'Invalid Nominators Enterprise ID';
       this.submitbtntooltip = 'Invalid Nominators Enterprise ID';
    //  this.noUpdatesneededbtntooltip = "Invalid Nominators Enterprise ID";
     // this.btnnopudatestooltipClass = '';
    }
    //else {
    //  this.noUpdatesneededbtntooltip = "Please add/update Skills and Areas of Expertise if needed";
     // this.btnnopudatestooltipClass = 'custom-tooltip';
    //}
  }
  
  return savesubmitbtndisabled;
}
getNominatorsEnterpriseValue(): string {
  return this.NominatorsEnterprise;
}
   GetSkillsandAOEsaveddata() {
    this.route.paramMap.subscribe(params => {
      this.NomineeEId = params.get('enterpriseid');
    });
    const EnterpriseId = this.NomineeEId;
    const skillsparams = { View: 0, EnterpriseId, AdminUser: this.currentusername, EID: this.currentusername };
    this.commonService.getSkills(skillsparams).subscribe(
      (response) => {
        this.SkillsTree_Data = response;
        this.skillsdataSource.data = this.SkillsTree_Data;
        Object.keys(this.skillsdataSource.data).forEach((key: any) => {
          this.setParent(this.skillsdataSource.data[key]);
        });

        const nomineeEidparams = { NomineeEnterpriseId: this.NomineeEId, AdminUser: this.currentusername };

        this.NomintionFormData$ = this.commonService.getNominationFormData(nomineeEidparams);

        forkJoin([this.NomintionFormData$, this.commonService.getSkills(skillsparams)]).subscribe(([nominationFormData, skillsData]) => {
          this.getSelectedSkills();
        });
      });
     this.AreaofExpertise();
  }
  async NominationFormTimeline() {
    const EnterpriseId = this.NomineeEId;
    if (EnterpriseId !== null || EnterpriseId !== 'undefined') {

      const paramEnterpriseId = {
        EnterpriseId,
        EID: this.currentusername
      };
      await this.commonService.getNominationFormTimeline(paramEnterpriseId).subscribe(
        (response) => {
          const timelinejsonString = JSON.stringify(response);
          const timelineparsedData: any[] = JSON.parse(timelinejsonString);
          this.FormStatusTimeline = timelineparsedData.map(
            (item) =>
              new FormStatusTimeline(
                item.FormStatusCd,
                item.TimelineLabel,
                item.TimelineFlag === 1,
                item.TimelineDesc
              )
          );
        }
      );
    }
  }

  async AreaofExpertise() {
    const EnterpriseId = this.NomineeEId;
    if (EnterpriseId !== null || EnterpriseId !== 'undefined') {

      const AOEparams = { View: 0, EnterpriseId, AdminUser: this.currentusername, EID: this.currentusername };
      await this.commonService.getAreaofExpertise(AOEparams).subscribe((response) => {
        this.AOEData = response;
        console.log("AOE DATA:",this.AOEData);
      });
    }
  }

  isPeoplePickerOpen: boolean = false;
  currentValue: string = 'Initial Value';
  nominatorEID: string | undefined;
  
  openPeoplePicker() {
    this.isPeoplePickerOpen = true;
  }
  
  onApplySelection(NominatorsEnterprise: string) {
    this.nominatorEID = NominatorsEnterprise;
    this.isPeoplePickerOpen = false;
  }
  onClosePopup() {
    this.isPeoplePickerOpen = false;
  }

  async getNominationFormData(): Promise<void> {
    this.isLoading = true;
    const nomineeEidparams = {
      NomineeEnterpriseId: this.NomineeEId,
      AdminUser: this.currentusername
    };
    await this.commonService.getNominationFormData(nomineeEidparams).subscribe((response: any) => {
      if (response.length !== 0) {
        if (response[0].AccessFlag === 'Invalid EnterpriseId') {
          this.isDataLoaded = false;
          this.invalidURLtxt = "Invalid URL, check enterprise ID";
        }
        else if (response[0].AccessFlag === 'No Access') {
          this.router.navigate(['/not-authorized']);
        }
        else {
          this.isDataLoaded = true;
          const nominationdataparsedData: any[] = response;
          this.FormID = response[0].FormId;
          this.QADFormFlag = response[0].CertificationTypeDesc === 'QAD' ? true : false;
          this.EliteQADFormFlag = response[0].CertificationTypeDesc === 'Elite QAD' ? true : false;
          this.rationaleforNominationtxt = response[0].RationaleNominationNotes === null ? '' : response[0].RationaleNominationNotes;
          this.previousFormStatus = response[0].PreviousFormStatusCd;
          this.FormStatus = response[0].FormStatusDesc;
          this.lblNominatorMarket = response[0].NominatorMarket;
                    if (this.isAdmin) {

            this.SCReviewDate = (response[0].SCReviewDt !== null && response[0].SCReviewDt !== "01-Jan-1970") ? this.parseDate(response[0].SCReviewDt) : '';
            this.SCReconsiderDate = (response[0].SCReconsiderDt !== null && response[0].SCReconsiderDt !== "01-Jan-1970") ? this.parseDate(response[0].SCReconsiderDt) : '';
            this.RegistrationCanceledDt = (response[0].RegistrationCanceledDt !== null && response[0].RegistrationCanceledDt !== "01-Jan-1970") ? this.parseDate(response[0].RegistrationCanceledDt) : '';
            
          }
          this.TrainingCompletionDt = (response[0].TrainingCompletionDt !== null && response[0].TrainingCompletionDt !== "01-Jan-1970") ? this.parseDate(response[0].TrainingCompletionDt) : '';
          this.MasteryTestCompletionDt = (response[0].MasteryTestCompletionDt !== null && response[0].MasteryTestCompletionDt !== "01-Jan-1970") ? this.parseDate(response[0].MasteryTestCompletionDt) : '';
          this.CaseStudyAssessmentCompletionDt = (response[0].CaseStudyAssessmentCompletionDt !== null && response[0].CaseStudyAssessmentCompletionDt !== "01-Jan-1970") ? this.parseDate(response[0].CaseStudyAssessmentCompletionDt) : '';
         if (this.isNominee || this.isReviewer || this.isReadOnly || (this.isAdmin && (response[0].FormStatusDesc === "On Hold" || response[0].FormStatusDesc === "Cancelled" || this.RegistrationCanceledDt))) {
            this.SCReviewDate = response[0].SCReviewDt !== null ? response[0].SCReviewDt : '';
            this.SCReconsiderDate = response[0].SCReconsiderDt !== null ? response[0].SCReconsiderDt : '';
            this.RegistrationCanceledDt = response[0].RegistrationCanceledDt !== null ? response[0].RegistrationCanceledDt : '';
          }

          if ((this.isAdmin && (this.RegistrationCanceledDt || this.FormStatus == 'Certified')) || this.isReviewer) {
            this.disablesteercoDateandDecison = true;
          }
          else {
            this.disablesteercoDateandDecison = false;
          }
          this.StreeCoDecisionsselected = response[0].SCDecisionCd === null ? 0 : response[0].SCDecisionCd;
          this.previousSteercoDecision = this.StreeCoDecisionsselected;
          if (response[0].SCDecisionCd === 1) {
            this.Streecocontroldisabled = true;
            this.registerationCanceldtdisable = false;
          }
          else {
            this.Streecocontroldisabled = false;
          }
          if (response[0].SCDecisionCd === 2 || response[0].SCDecisionCd === 3) {
            this.registerationCanceldtdisable = true;
          }
          //this.StreeCoDecisionDesc = this.drpdwnStreeCoDecisions.find(scdecision => scdecision.SCDecisionCd === response[0].SCDecisionCd)
          this.StreeCoDecisionDesc = response[0].SCDecisionDesc;

          this.steercoNotes = (response[0].SCDecisionNotes !== null) ? response[0].SCDecisionNotes : '';
          //this.RegistrationCanceledDt = response[0].RegistrationCanceledDt !== null ? this.parseDate(response[0].RegistrationCanceledDt) : '';
          this.NominatorEID = response[0].NominatorEID;
          this.nominatorflagEID = response[0].NominatorEID;
          this.AOEflag = response[0].NomineeAreasOfExpertiseIds;
          this.SkillsFlag = response[0].NomineeExpertiseSkillIds;
          this.previousAOEIds = response[0].NomineeAreasOfExpertiseIds;
          this.previousSkillsIds = response[0].NomineeExpertiseSkillIds;
          this.NominatorsEnterprise = response[0].NominatorEID;
          this.EQADNominateFlag = response[0].EQADNominateFlag == 1 ? true : false;
          if (response[0].RationaleNominationQADNotes === "" || response[0].RationaleNominationQADNotes === null) {
            this.qadRationaleText = response[0].RationaleNominationQADNotes;
          }
          else {
            this.qadRationaleText = 'QAD Rationale: ' + response[0].RationaleNominationQADNotes;
          }
          this.NominationFormData = nominationdataparsedData.map(
            (item) => new getNominationForm(item)
          );
          if (!this.nominatorflagEID || !this.AOEflag) {
            this.noupdatesbtnvisiblecheck = false;

         }
          else {
            this.noupdatesbtnvisiblecheck = true;
          }
          //if (this.FormAOEIds) {

          //  const selectedSkillsitem = this.getselectedSkillFrmSkilldatasource(this.FormAOEIds);
          //  this.skillsCheckedNodes = [];
          //  selectedSkillsitem.forEach(skilldt =>
          //    this.skillsCheckedNodes.push({ id: skilldt.id, skillsExpertiseNm: skilldt.skillsExpertiseNm })
          //  );      
          //}
          this.isContractor = response[0].IsQADContractorFlag == 1;
          //console.log("Is Contractor:", this.isContractor); // Debugging
          this.isDataLoaded = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        }

      }
      else {
        this.router.navigate(['/not-authorized']);
      }
    },
      (error: any) => {
        console.log(error);
      }
    );
   
  }
  
  async getSelectedSkills() {
    const nomineeEidparams = { NomineeEnterpriseId: this.NomineeEId };
    try {
      const nominationformdata = await this.NomintionFormData$.toPromise();
      if (nominationformdata.length !== 0) {
        if (nominationformdata[0].NomineeExpertiseSkillIds !== null) {
          const selectedSkillsitem = await this.getselectedSkillFrmSkilldatasource(nominationformdata[0].NomineeExpertiseSkillIds);
          let newSkillsCheckedNodes: any;

          if (selectedSkillsitem) {
            newSkillsCheckedNodes = selectedSkillsitem.map(skilldt => ({
              id: skilldt.expertiseSkillId,
              skillsExpertiseNm: skilldt.skillsExpertiseNm

            }));
          }
          this.skillsCheckedNodes = newSkillsCheckedNodes;
          //selectedSkillsitem.forEach(skilldt =>
          //  this.skillsCheckedNodes.push({ id: skilldt.expertiseSkillId, skillsExpertiseNm: skilldt.skillsExpertiseNm })
          //);

        }
      }

    }
    catch (error) {
      console.log(error);
    }
  }


  async getselectedSkillFrmSkilldatasource(skillids: string): Promise<SkillsTreeNode[]> {
    const skillidArray = skillids.split(',').map(Number); // Split the IDs into an array of numbers
    let selectedSkillNode: SkillsTreeNode[] = [];
    const SelectedSkillspromises = skillidArray.map(async (skillid) => {
      const skillNode = await this.findSelectedNodeBySkillid(this.skillsdataSource.data, skillid);
      if (skillNode) {
        selectedSkillNode.push(skillNode);
      }
    });

    await Promise.all(SelectedSkillspromises);

    //for (const skillid of skillidArray) {
    //  const skillNode =await this.findSelectedNodeBySkillid(this.skillsdataSource.data, skillid);
    //  if (skillNode) {
    //    selectedSkillNode = selectedSkillNode.concat(skillNode);
    //  }
    //}

    return selectedSkillNode;
  }

  async findSelectedNodeBySkillid(skillnodes: SkillsTreeNode[], skillid: number): Promise<SkillsTreeNode> {
    //  let selectedskillnode: any;
    //  for (const skillnode of skillnodes) {
    //    if (skillnode.expertiseSkillId === skillid) {
    //      selectedskillnode = skillnode;
    //    }
    //    if (skillnode.children) {
    //      const skillChildNode =  this.findSelectedNodeBySkillid(skillnode.children, skillid);
    //      if (skillChildNode) {
    //        selectedskillnode = skillChildNode;
    //      }
    //    }
    //  }
    //return selectedskillnode;
    async function selectedSkillssearch(nodes: SkillsTreeNode[]): Promise<SkillsTreeNode | null> {
      for (const skillnode of nodes) {
        if (skillnode.expertiseSkillId === skillid) {
          return skillnode;
        }
        if (skillnode.children) {
          const skillChildNode = await selectedSkillssearch(skillnode.children);
          if (skillChildNode) {
            return skillChildNode;
          }
        }
      }
      return null;
    }

    const selectedSkillNode = await selectedSkillssearch(skillnodes);
    return selectedSkillNode!;

  }

  getSelectedAOEIds(): string {
    const selectedAOEItems = this.AOEData.filter(item => item.AOECheckInd);
    return selectedAOEItems.map(item => item.AreaOfExpertiseId.toString()).join(',');
  }
  getselectedSkillsIds(): string {
    const selectedSkillsItems = this.skillsCheckedNodes.filter(item => item.id);
    return selectedSkillsItems.map(item => item.id.toString()).join(',');
  }
  tooltipStyle() {
    const tooltipElements = document.querySelectorAll('.mdc-tooltip__surface');

    if (tooltipElements) {

      tooltipElements.forEach((tooltipElement) => {
        if (tooltipElement.classList.contains('mdc-tooltip__surface')) {
          tooltipElement.classList.add('tooltipstyle');

        }
      });

    }
  }
    isNominateforEqadbtnDisabled() : boolean{
    let btnNominatedisabled = false;
    this.NomimateEQADbtntooltip = '';
      if(this.SCReviewDate==="" && this.StreeCoDecisionsselected === 1){
        btnNominatedisabled = true;
        this.tooltipStyle();
        this.NomimateEQADbtntooltip = 'Nomination is only allowed post reactivation.';
      }
      else if(this.SCReviewDate==="" && (this.StreeCoDecisionsselected === 2 || this.StreeCoDecisionsselected === 3)) {
        btnNominatedisabled = true;
        this.tooltipStyle();
        this.NomimateEQADbtntooltip = 'Nomination is only allowed post reactivation.';
      }
      else if(this.SCReviewDate !=="" && this.StreeCoDecisionsselected === 0){
        btnNominatedisabled = true;
        this.tooltipStyle();
        this.NomimateEQADbtntooltip = 'Nomination is only allowed post reactivation.';
      }
      else if(this.SCReviewDate !=="" && (this.StreeCoDecisionsselected === 2 || this.StreeCoDecisionsselected === 3)){
        btnNominatedisabled = true;
        this.tooltipStyle();
        this.NomimateEQADbtntooltip = 'Nomination is only allowed post reactivation.';
      }
      else if(!(this.RegistrationCanceledDt === "" || this.RegistrationCanceledDt === "null")){
        btnNominatedisabled = true;
        this.tooltipStyle();
        this.NomimateEQADbtntooltip = 'Nomination is only allowed post reactivation.';
      }
    
    return btnNominatedisabled;
  }
  
  isSaveSubmitbtnEnabled(): boolean {

    let btnSubmitdisabled = false;
    this.AOEData.some(item => item.AOECheckInd)
    const selectedAOEIds = this.AOEData.some(item => item.AOECheckInd);
    // const selectedSkillsIds = (this.getselectedSkillsIds().length > 0);
    this.submitbtntooltip = '';
    this.savebtntooltip = '';
    this.tooltipflag = false;
    if (this.isAdmin) {
    if(this.RegistrationCanceledDt === "" || this.RegistrationCanceledDt === "null"){
      
      if ((!selectedAOEIds ) && (this.SCReviewDate !== "" && this.StreeCoDecisionsselected === 0)) {

        btnSubmitdisabled = true;
        this.tooltipflag = true;
        this.savebtntooltip = 'Areas of Expertise must be populated.SteerCo Review Date and SteerCo Decision fields must both be populated.';
        this.submitbtntooltip = 'Areas of Expertise must be populated.SteerCo Review Date and SteerCo Decision fields must both be populated.';

      }
      else if ((!selectedAOEIds) && (this.StreeCoDecisionsselected === 1 && this.SCReviewDate === "")) {
        this.tooltipflag = true;
        btnSubmitdisabled = true;
        this.savebtntooltip = 'Areas of Expertise must be populated.SteerCo Review Date and SteerCo Decision fields must both be populated.';
        this.submitbtntooltip = 'Areas of Expertise must be populated.SteerCo Review Date and SteerCo Decision fields must both be populated.';

      }     
      else if ((!selectedAOEIds ) && ((this.StreeCoDecisionsselected === 2 || this.StreeCoDecisionsselected === 3) && this.SCReviewDate === "")) {
        btnSubmitdisabled = true;
        this.tooltipStyle();
        this.savebtntooltip = 'SteerCo Review Date and SteerCo Decision fields must both be populated.';
        this.submitbtntooltip = 'SteerCo Review Date and SteerCo Decision fields must both be populated.';
      }
      else if (!(this.StreeCoDecisionsselected === 2 || this.StreeCoDecisionsselected === 3) && (!selectedAOEIds )) {
        btnSubmitdisabled = true;
        this.nominatortooltipStyle();
        this.savebtntooltip = 'Areas of Expertise must be populated.';
        this.submitbtntooltip = 'Areas of Expertise must be populated.';
      }


      else if (selectedAOEIds) {
        if (this.SCReviewDate !== "") {
          if (this.StreeCoDecisionsselected === 0) {
            btnSubmitdisabled = true;
            this.tooltipStyle();
            this.submitbtntooltip = 'SteerCo Review Date and SteerCo Decision fields must both be populated.'
            this.savebtntooltip = 'SteerCo Review Date and SteerCo Decision fields must both be populated.';
          }
        }
        else if (this.StreeCoDecisionsselected !== 0) {
          if (this.SCReviewDate === "") {
            btnSubmitdisabled = true;
            this.tooltipStyle();
            this.submitbtntooltip = 'SteerCo Review Date and SteerCo Decision fields must both be populated.'
            this.savebtntooltip = 'SteerCo Review Date and SteerCo Decision fields must both be populated.';
          }
        }
        else {
          btnSubmitdisabled = false;
          this.submitbtntooltip = '';
          this.savebtntooltip = '';
        }
      }

    }
    else{
      btnSubmitdisabled = false;
    }
  }
    if (this.isReviewer) {
      if (!selectedAOEIds ) {
        btnSubmitdisabled = true;
        this.nominatortooltipStyle();
        this.savebtntooltip = 'Areas of Expertise must be populated.';
        this.submitbtntooltip = 'Areas of Expertise must be populated.';
      }
    }
    if (this.isNominee) {
      if (selectedAOEIds ) {
        btnSubmitdisabled = false;
        this.submitbtntooltip = '';
      }
      else {
        btnSubmitdisabled = true;
        this.tooltipStyle();
        this.submitbtntooltip = 'All fields are required. Please select your Areas of Expertise.';
      }
    }
    return btnSubmitdisabled;
  }

  isApprovebtnDisabled(): boolean {
    const selectedAOEIds = this.AOEData.some(item => item.AOECheckInd);
    //const selectedSkillsIds = (this.getselectedSkillsIds().length > 0);
    let btnApprovedisabled = false;
    this.approvebtntooltip = "";
    this.btnapprovetooltipClass = "";
    let AOESkillsSelectedflag = true;
    if (selectedAOEIds) {
      AOESkillsSelectedflag = false;
      btnApprovedisabled = false;
    }
    else {
      AOESkillsSelectedflag = true;
      btnApprovedisabled = true;
      // if (this.tooltipflag) {
      //   this.btnapprovetooltipClass = 'custom-tooltip';
      // }
      this.nominatortooltipStyle();
      this.approvebtntooltip = 'Areas of Expertise must be populated.';
    }

    if (this.isAdmin) {
      const reviewDate = this.SCReviewDate !== "";
      const steercoDecisions = this.StreeCoDecisionsselected !== 0;
      if (reviewDate && steercoDecisions) {
        btnApprovedisabled = true;
      }
    }
    return btnApprovedisabled;
  }

  submitNomineeForm(validationModel: any): void {
    this.isLoading = true;
    if (this.rationaleforNominationtxt.search(';') != -1) {
      this.rationaleforNominationtxt = this.rationaleforNominationtxt.replaceAll(';', '¥');
    }
    if (this.steercoNotes.search(';') != -1) {
      this.steercoNotes = this.steercoNotes.replaceAll(';', '¥');
    }
  
    const nominationpostData = {
      FormId: this.FormID,
      NomineeEnterpriseId: this.NomineeEId,
      AoEID: this.getSelectedAOEIds(),
      ExpertSkillsId: this.getselectedSkillsIds(),
      AdminUser: this.currentusername,
      SteerCoReviewDt: (this.SCReviewDate === undefined || this.SCReviewDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReviewDate)),
      SteerCoReconsiderDt: (this.SCReconsiderDate === undefined || this.SCReconsiderDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReconsiderDate)),
      SteerCoDecisionCd: this.StreeCoDecisionsselected === null ? 0 : this.StreeCoDecisionsselected,
      SteerCoNotes: this.steercoNotes,
      RegistrationCancelDt: (this.RegistrationCanceledDt === undefined || this.RegistrationCanceledDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.RegistrationCanceledDt)),
      RationaleNotes: this.rationaleforNominationtxt,
      FormButtonSubmitFlag: 4,
      NominatorEnterpriseId: this.NominatorEID,
      MasteryTestCompletionDt: (this.MasteryTestCompletionDt === undefined || this.MasteryTestCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.MasteryTestCompletionDt)),
      TrainingCompletionDt: (this.TrainingCompletionDt === undefined || this.TrainingCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.TrainingCompletionDt)),
      CaseStudyAssessmentCompletionDt: (this.CaseStudyAssessmentCompletionDt === undefined || this.CaseStudyAssessmentCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.CaseStudyAssessmentCompletionDt)),

    }
    this.commonService.postNominationFormData(nominationpostData)
      .subscribe(
        (response: any) => {
          //console.log('API response:', response); 
          if (response[0].data === 'Success') {
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
            this.modalRef = this.modalService.open(validationModel, { windowClass: "submitbtnvalidation", centered: true, });
            const backdropElement = this.modalRef['_windowCmptRef'].location.nativeElement.previousElementSibling;
            backdropElement.id = 'submitbtnbackdrop';
            const footercont = document.getElementById('footercontainer') as HTMLDivElement;
            footercont.classList.add('maskedoutfooter');
            const footerconts = document.getElementById('copyright') as HTMLDivElement;
            footerconts.classList.add('footerCopyrightNewColor');

            this.getNominationFormData();
            this.GetSkillsandAOEsaveddata();
            this.NominationFormTimeline();

          }

        },
        (error) => {
          console.error('API error:', error);
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        }
      );

  }
  closeSubmitValidationDialog(): void {
    this.modalService.dismissAll();
    const footercont = document.getElementById('footercontainer') as HTMLDivElement;
    footercont.classList.remove('maskedoutfooter');
    const footerconts = document.getElementById('copyright') as HTMLDivElement;
    footerconts.classList.remove('footerCopyrightNewColor');
  }

  approveNomineeForm() {
    this.isLoading = true;
    if (this.rationaleforNominationtxt.search(';') != -1) {
      this.rationaleforNominationtxt = this.rationaleforNominationtxt.replaceAll(';', '¥');
    }
    if (this.steercoNotes.search(';') != -1) {
      this.steercoNotes = this.steercoNotes.replaceAll(';', '¥');
    }
    const nominationpostData = {
      FormId: this.FormID,
      NomineeEnterpriseId: this.NomineeEId,
      AoEID: this.getSelectedAOEIds(),
      ExpertSkillsId: this.getselectedSkillsIds(),
      AdminUser: this.currentusername,
      SteerCoReviewDt: (this.SCReviewDate === undefined || this.SCReviewDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReviewDate)),
      SteerCoReconsiderDt: (this.SCReconsiderDate === undefined || this.SCReconsiderDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReconsiderDate)),
      SteerCoDecisionCd: this.StreeCoDecisionsselected === null ? 0 : this.StreeCoDecisionsselected,
      SteerCoNotes: this.steercoNotes,
      RegistrationCancelDt: (this.RegistrationCanceledDt === undefined || this.RegistrationCanceledDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.RegistrationCanceledDt)),
      RationaleNotes: this.rationaleforNominationtxt,
      FormButtonSubmitFlag: 2,
      NominatorEnterpriseId: this.NominatorEID,
       MasteryTestCompletionDt: (this.MasteryTestCompletionDt === undefined || this.MasteryTestCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.MasteryTestCompletionDt)),
       TrainingCompletionDt: (this.TrainingCompletionDt === undefined || this.TrainingCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.TrainingCompletionDt)),
       CaseStudyAssessmentCompletionDt: (this.CaseStudyAssessmentCompletionDt === undefined || this.CaseStudyAssessmentCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.CaseStudyAssessmentCompletionDt)),
 }
    this.commonService.postNominationFormData(nominationpostData)
      .subscribe(
        (response) => {
          if (response[0].data === 'Success') {
            this.getNominationFormData();
            this.GetSkillsandAOEsaveddata();
            this.NominationFormTimeline();
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
        },
        (error) => {
          console.error('API error:', error);
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);

        }
      );

  }
  saveNomineeForm() {
    this.isLoading = true;
    if (this.rationaleforNominationtxt.search(';') != -1) {
      this.rationaleforNominationtxt = this.rationaleforNominationtxt.replaceAll(';', '¥');
    }
    if (this.steercoNotes.search(';') != -1) {
      this.steercoNotes = this.steercoNotes.replaceAll(';', '¥');
    }
    const nominationpostData = {
      FormId: this.FormID,
      NomineeEnterpriseId: this.NomineeEId,
      AoEID: this.getSelectedAOEIds(),
      ExpertSkillsId: this.getselectedSkillsIds(),
      AdminUser: this.currentusername,
      SteerCoReviewDt: (this.SCReviewDate === undefined || this.SCReviewDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReviewDate)),
      SteerCoReconsiderDt: (this.SCReconsiderDate === undefined || this.SCReconsiderDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReconsiderDate)),
      SteerCoDecisionCd: this.StreeCoDecisionsselected === null ? 0 : this.StreeCoDecisionsselected,
      SteerCoNotes: this.steercoNotes,
      RegistrationCancelDt: (this.RegistrationCanceledDt === undefined || this.RegistrationCanceledDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.RegistrationCanceledDt)),
      RationaleNotes: this.rationaleforNominationtxt,
      FormButtonSubmitFlag: 3,
      NominatorEnterpriseId: this.NominatorEID,
      MasteryTestCompletionDt: (this.MasteryTestCompletionDt === undefined || this.MasteryTestCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.MasteryTestCompletionDt)),
      TrainingCompletionDt: (this.TrainingCompletionDt === undefined || this.TrainingCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.TrainingCompletionDt)),
      CaseStudyAssessmentCompletionDt: (this.CaseStudyAssessmentCompletionDt === undefined || this.CaseStudyAssessmentCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.CaseStudyAssessmentCompletionDt)),
     }
    console.log("nominationpostdata:",nominationpostData);
    this.commonService.postNominationFormData(nominationpostData)
      .subscribe(
        (response) => {
          if (response[0].data === 'Success') {
            this.getNominationFormData();
            this.GetSkillsandAOEsaveddata();
            this.NominationFormTimeline();
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
        },
        (error) => {
          console.error('API error:', error);
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        }
      );

  }

  NominateEQADForm() {
    if (this.rationaleforNominationtxt.search(';') != -1) {
      this.rationaleforNominationtxt = this.rationaleforNominationtxt.replaceAll(';', '¥');
    }
    if (this.steercoNotes.search(';') != -1) {
      this.steercoNotes = this.steercoNotes.replaceAll(';', '¥');
    }
    this.isLoading = true;

    const nominationpostData = {
      FormId: this.FormID,
      NomineeEnterpriseId: this.NomineeEId,
      AoEID: this.getSelectedAOEIds(),
      ExpertSkillsId: this.getselectedSkillsIds(),
      AdminUser: this.currentusername,
      SteerCoReviewDt: (this.SCReviewDate === undefined || this.SCReviewDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReviewDate)),
      SteerCoReconsiderDt: (this.SCReconsiderDate === undefined || this.SCReconsiderDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReconsiderDate)),
      SteerCoDecisionCd: this.StreeCoDecisionsselected === null ? 0 : this.StreeCoDecisionsselected,
      SteerCoNotes: this.steercoNotes,
      RegistrationCancelDt: (this.RegistrationCanceledDt === undefined || this.RegistrationCanceledDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.RegistrationCanceledDt)),
      RationaleNotes: this.rationaleforNominationtxt,
      FormButtonSubmitFlag: 1,
      NominatorEnterpriseId: this.NominatorEID,
      MasteryTestCompletionDt: (this.MasteryTestCompletionDt === undefined || this.MasteryTestCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.MasteryTestCompletionDt)),
      TrainingCompletionDt: (this.TrainingCompletionDt === undefined || this.TrainingCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.TrainingCompletionDt)),
      CaseStudyAssessmentCompletionDt: (this.CaseStudyAssessmentCompletionDt === undefined || this.CaseStudyAssessmentCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.CaseStudyAssessmentCompletionDt)),

    }
    console.log("check" + nominationpostData.AoEID);
    this.commonService.postNominationFormData(nominationpostData)
      .subscribe(
        (response) => {

          if (response[0].data === 'Success') {
            this.getNominationFormData();
            this.GetSkillsandAOEsaveddata();
            this.NominationFormTimeline();
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
        },
        (error) => {
          console.error('API error:', error);
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        }
      );

  }

  noUpdatesNeededNomineeForm() {
    this.isLoading = true;
    if (this.rationaleforNominationtxt.search(';') != -1) {
      this.rationaleforNominationtxt = this.rationaleforNominationtxt.replaceAll(';', '¥');
    }
    if (this.steercoNotes.search(';') != -1) {
      this.steercoNotes = this.steercoNotes.replaceAll(';', '¥');
    }
    const nominationpostData = {
      FormId: this.FormID,
      NomineeEnterpriseId: this.NomineeEId,

      AoEID: this.getSelectedAOEIds(),
      ExpertSkillsId: this.getselectedSkillsIds(),
      AdminUser: this.currentusername,
      SteerCoReviewDt: (this.SCReviewDate === undefined || this.SCReviewDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReviewDate)),
      SteerCoReconsiderDt: (this.SCReconsiderDate === undefined || this.SCReconsiderDate === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.SCReconsiderDate)),
      SteerCoDecisionCd: this.StreeCoDecisionsselected === null ? 0 : this.StreeCoDecisionsselected,
      SteerCoNotes: this.steercoNotes,
      RegistrationCancelDt: (this.RegistrationCanceledDt === undefined || this.RegistrationCanceledDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.RegistrationCanceledDt)),
      RationaleNotes: this.rationaleforNominationtxt,
      FormButtonSubmitFlag: 6,
      NominatorEnterpriseId: this.NominatorEID,
      MasteryTestCompletionDt: (this.MasteryTestCompletionDt === undefined || this.MasteryTestCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.MasteryTestCompletionDt)),
      TrainingCompletionDt: (this.TrainingCompletionDt === undefined || this.TrainingCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.TrainingCompletionDt)),
      CaseStudyAssessmentCompletionDt: (this.CaseStudyAssessmentCompletionDt === undefined || this.CaseStudyAssessmentCompletionDt === "") ? this.formatDateToYYYYMMDD(new Date(this.getDefaultUTCDate())) : this.formatDateToYYYYMMDD(new Date(this.CaseStudyAssessmentCompletionDt)),

    }
    console.log("nominationpostdata:", nominationpostData);
    this.commonService.postNominationFormData(nominationpostData)
      .subscribe(
        (response) => {
          if (response[0].data === 'Success') {
            this.getNominationFormData();
            this.GetSkillsandAOEsaveddata();
            this.NominationFormTimeline();
            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
        },
        (error) => {
          console.error('API error:', error);
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        }
      );

  }

  //Date Coversion start
  // Function to convert "dd-mmm-yyyy" string to a Date object
  parseDate(DateStr: string): Date {
    const dateParts = DateStr.split('-');
    const day = parseInt(dateParts[0], 10);
    const month = this.getMonthNumber(dateParts[1]);
    const year = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
  }

  //Helper function to convert month abbreviation to a month number
  getMonthNumber(abbr: string): number {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return monthNames.indexOf(abbr);
  }

  formatDateToYYYYMMDD(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JavaScript
    let year = date.getFullYear().toString();
    if (year.length > 4) {
       year = year.slice(0, 4);
    }
    return `${year}-${month}-${day}`;
  }

  getDefaultUTCDate(): Date {
    // Get the current UTC date and time
    const currentDate = new Date('1900-01-01');

    // Convert it to UTC by setting the time zone offset to 0
    const utcDate = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds()
    );

    return utcDate;
  }

  onDateChange(event: any, datepickerName: string) {
    const inputdateValue = event.target.value;
    const enteredDate = new Date(inputdateValue);
    
    switch (datepickerName) {
      case 'reviewdtpicker':
        if (!isNaN(enteredDate.getDate())) {
          this.SCReviewDate = this.formatDateToYYYYMMDD(enteredDate);
        } else {
          this.SCReviewDate = "";
        }
        break;

      case 'reconsiderdtpicker':
        if (!isNaN(enteredDate.getDate())) {
          this.SCReconsiderDate = this.formatDateToYYYYMMDD(enteredDate);
        }
        else {
          this.SCReconsiderDate = "";
        }
        break;
      case 'CredentialCancelpicker':
        if (!isNaN(enteredDate.getDate())) {
          this.RegistrationCanceledDt = this.formatDateToYYYYMMDD(enteredDate);
          if (this.RegistrationCanceledDt) {
           // this.StreeCoDecisionDesc = "Cancel";
           // this.StreeCoDecisionsselected = 2;
            this.disablesteercoDateandDecison = true;
            this.Streecocontroldisabled = false;
          }
          else {
            this.StreeCoDecisionsselected = this.previousSteercoDecision;
            const StreecoDecision = this.drpdwnStreeCoDecisions.find(scdecision => scdecision.SCDecisionCd === this.StreeCoDecisionsselected);
          //  this.StreeCoDecisionDesc = (StreecoDecision != undefined) ? StreecoDecision.SCDecisionDesc : "";
            if (this.FormStatus == 'Certified') {
              this.disablesteercoDateandDecison = true;
              this.Streecocontroldisabled = true;
            }
            else {
              this.disablesteercoDateandDecison = false;
              if (this.StreeCoDecisionsselected === 1) {
                this.Streecocontroldisabled = true;
              }
            }
          }

        }
        else {
          this.RegistrationCanceledDt = "";

        }
        break;
        case 'trainingpicker':
          if (!isNaN(enteredDate.getDate())) {
            this.TrainingCompletionDt = this.formatDateToYYYYMMDD(enteredDate);
          } else {
            this.TrainingCompletionDt = "";
          }
        break;
        case 'masterytestpicker':
          if (!isNaN(enteredDate.getDate())) {
            this.MasteryTestCompletionDt = this.formatDateToYYYYMMDD(enteredDate);
          } else {
            this.MasteryTestCompletionDt = "";
          }
        break;
        case 'casestudyassessmentpicker':
          if (!isNaN(enteredDate.getDate())) {
            this.CaseStudyAssessmentCompletionDt = this.formatDateToYYYYMMDD(enteredDate);
          } else {
            this.CaseStudyAssessmentCompletionDt = "";
          }
        break;
        default:
        break;
    }

  }

  registerationCanceldateinputEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const inputregisteraioncancelddtValue = event.target.value;

    if (inputregisteraioncancelddtValue) {
     // this.StreeCoDecisionDesc = "Cancel";
      //this.StreeCoDecisionsselected = 2;
      this.disablesteercoDateandDecison = true;
      this.Streecocontroldisabled = false;
    }
    else {
      this.StreeCoDecisionsselected = this.previousSteercoDecision;
      const StreecoDecision = this.drpdwnStreeCoDecisions.find(scdecision => scdecision.SCDecisionCd === this.StreeCoDecisionsselected);
     // this.StreeCoDecisionDesc = (StreecoDecision != undefined) ? StreecoDecision.SCDecisionDesc : "";
      if (this.FormStatus == 'Certified') {
        this.disablesteercoDateandDecison = true;
        this.Streecocontroldisabled = true;
      }
      else {
        this.disablesteercoDateandDecison = false;
        if (this.StreeCoDecisionsselected === 1) {
          this.Streecocontroldisabled = true;
        }
      }
    }
  }
  //Date Coversion start end

  onStreeCoDecisionsSelected(event: any): void {
    if (event.value === 1) {
      this.SCReconsiderDate = '';
      this.Streecocontroldisabled = true;
      this.registerationCanceldtdisable = false;
    }
    else {
      this.Streecocontroldisabled = false;
    }
    if (event.value === 2 || event.value === 3) {
      this.registerationCanceldtdisable = true;
      this.Streecocontroldisabled = false;
    }
    else {
      this.registerationCanceldtdisable = false;
    }

  }
  restrictspecialcharacters(event: any) {
    const k = event.which || event.keyCode;
    if (
      (k >= 65 && k <= 90) ||       // A-Z

      (k >= 97 && k <= 122) ||      // a-z

      (k > 47 && k < 58) ||

      k === 32 ||                   // Space

      k === 36 ||                   // Dollar sign '$'

      k === 37 ||                   // Percent sign '%'

      k === 38 ||                   // Ampersand '&'

      (k >= 40 && k <= 41) ||       // Parentheses '(' and ')'

      k === 45 ||                   // Hyphen '-'

      k === 95 ||                   // Underscore '_'

      k === 47 ||                   // Slash '/'

      k === 58 ||                   // Colon ':'

      k === 59 ||                   // Semicolon ';'

      k === 34 ||                   // Double quote '"'

      k === 39 ||                   // Single quote "'"

      k === 44 ||                   // Comma ','

      k === 46 ||                   // Period '.'

      k === 63                      // Question mark '?'

    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  openSkilldialog(skillcontent: any, event: any) {
    this.SkillsClickicon = 'assets/img/NominationForm/add_circle_open.svg';
    let resl: SkillsTreeNode[] = [];
    this.skillstreeControl.collapseAll();
    this.modalRef = this.modalService.open(skillcontent, { ariaLabelledBy: 'modal-basic-title', windowClass: "skillstemplate" });
    const backdropElement = this.modalRef['_windowCmptRef'].location.nativeElement.previousElementSibling;
    backdropElement.id = 'skillsbackdrop';
    let el = event.srcElement;
    let elementoffsettop: any = "";
    let offsettop = el.getBoundingClientRect().top;
    const boxes = Array.from(document.getElementsByClassName('modal-content') as HTMLCollectionOf<HTMLElement>,);
    if (offsettop <= 212) {
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
      boxes.forEach(boxes => {
        boxes.style.marginTop = '353px';
      });
    }
    else {
      elementoffsettop = offsettop - 210;
    }

    boxes.forEach(boxes => {
      boxes.style.marginTop = elementoffsettop + "px";
    });
    for (let i = 0; i < this.skillsCheckedNodes.length; i++) {
      this.skillsdataSource.data.forEach(node => {
        resl = resl.concat(
          this.skillstreeControl
            .getDescendants(node)
            .filter(x => x.skillsExpertiseNm == this.skillsCheckedNodes[i].skillsExpertiseNm).map(x => x)
        );
      });
    }

    for (let i = 0; i < resl.length; i++) {
      this.Toggle(true, resl[i])
    }
    this.isModalOpen = true;
  }
  closeSkillPopupDialogue(): void {
    this.skillsSearchString = '';
    this.skillsFilterNodes(this.skillsSearchString);
    this.SkillsClickicon = 'assets/img/NominationForm/add_circle_outline_purple.svg';
    this.modalService.dismissAll();
    this.isModalOpen = false;
  }


  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.modalRef && !this.el.nativeElement.contains(event.target)) {
      this.skillsSearchString = '';
      this.skillsFilterNodes(this.skillsSearchString);
      this.SkillsClickicon = 'assets/img/NominationForm/add_circle_outline_purple.svg';
      this.modalRef.close();
    }
    const footercont = document.getElementById('footercontainer') as HTMLDivElement;
    footercont.classList.remove('maskedoutfooter');
    const footerconts = document.getElementById('copyright') as HTMLDivElement;
    footerconts.classList.remove('footerCopyrightNewColor');
  }
  onskillsSearchtxtboxClick(event: Event) {
    event.stopPropagation();
  }
  uncheckSkillsNode(content: string) {
    let res: SkillsTreeNode[] = [];
    //this.checkedNodes.filter(x => x.skillsExpertiseNm === content);
    //this.checkedNodes.splice(this.checkedNodes.findIndex(e => e.skillsExpertiseNm === content), 1);
    this.skillsCheckedNodes.filter(x => x.skillsExpertiseNm === content);
    this.skillsCheckedNodes.splice(this.skillsCheckedNodes.findIndex(e => e.skillsExpertiseNm === content), 1);

    this.SkillsTree_Data.forEach(node => {
      res = res.concat(
        this.skillstreeControl
          .getDescendants(node)
          .filter(x => x.skillsExpertiseNm === content)
          .map(x => x)
      );
    });

    this.Toggle(false, res[0])
  }
  public Toggle(checked: boolean, node: SkillsTreeNode) {
    this.skillscheckboxStates[node.skillsExpertiseNm] = checked;
    node.isChecked = checked;

    if (node.children) {
      node.children.forEach(child => {
        if (!child.disableSecSkillsFlag) { 
          this.skillsitemToggle(checked, child);
        }
      });
    }
    this.checkAllParents(node);
  }

  setParent(node: SkillsTreeNode, parent?: SkillsTreeNode) {
    node.parent = parent;
    if (node.children) {
      node.children.forEach(childNode => {
        this.setParent(childNode, node);
        if (childNode.isChecked == true) {
          this.checklist.push(childNode.skillsExpertiseNm);
        }
      });
    }

  }

  hasChild = (_: number, node: SkillsTreeNode) => {
    return !!node.children && (node.children.length > 0 || this.expandedNodes.has(node));
  }


  public skillsitemToggle(checked: boolean, node: SkillsTreeNode) {
    //this.skillscheckboxStates[node.skillsExpertiseNm] = checked;
    // node.isChecked = checked;

    const dataNode = this.findNodeInNestedTree(node, this.SkillsTree_Data);
    if (dataNode) {
      dataNode.isChecked = checked;
    }

    // Update the toggle node
    node.isChecked = checked;
    this.skillscheckboxStates[node.skillsExpertiseNm] = checked;
    if (node.children && node.disableSecSkillsFlag===0) {
      node.children.forEach(child => {
        if (child.disableSecSkillsFlag===0) {
          this.skillsitemToggle(checked, child);
        }
      }
      );
    }

    if (!checked) {
      this.updateParentCheckboxStates(node);
    }
    //if (node.children) {
    //  node.children.forEach(child => {
    //    this.skillsitemToggle(checked, child);
    //  });
    //}
    //Sync the checked state of filtered node with Skills treenode 
    const secondParentNode = this.findNodeInNestedTree(node, this.SkillsTree_Data);

    if (secondParentNode) {
      this.updateChildrenRecursively(secondParentNode, checked);
    }

    this.checkAllParents(node);
    let resultselectedskills: SkillsTreeNode[] = [];
    this.checkedNodes = [];
    this.skillsCheckedNodes = [];

    //for (const key in this.skillscheckboxStates) {
    //  if (this.skillscheckboxStates.hasOwnProperty(key)) {
    //    const value = this.skillscheckboxStates[key];
    //    console.log(`Key1: ${key}, Value1: ${value}`);
    //  }
    //}

    const selectedNodeNames = Object.keys(this.skillscheckboxStates).filter(key => this.skillscheckboxStates[key] === true);
    //this.SkillsTree_Data.forEach(node => {
    //     re = re.concat(
    //    this.skillstreeControl
    //      .getDescendants(node)
    //      .filter(x => x.isChecked && x.children?.length === 0)
    ////      .filter(x => x.isChecked && x.children?.length === 0)
    //      .map(x => x)
    // );
    //});

    let SelectedSkillsnode: any[] = [];

    this.SkillsTree_Data.forEach(node => {

      const findSelectedSkillsnode = (currentNode: SkillsTreeNode) => {
        if (selectedNodeNames.includes(currentNode.skillsExpertiseNm)) {
          SelectedSkillsnode.push(currentNode);

        }

        if (currentNode.children) {
          currentNode.children.forEach(child => {
            findSelectedSkillsnode(child);
          });
        }
      };

      findSelectedSkillsnode(node);
    });

    resultselectedskills = SelectedSkillsnode;
    for (let i = 0; i < resultselectedskills.length; i++) {
      if (resultselectedskills[i].children?.length === 0) {
        this.skillsCheckedNodes.push({ id: resultselectedskills[i].expertiseSkillId, skillsExpertiseNm: resultselectedskills[i].skillsExpertiseNm });

      }
    }

  }
  private checkAllParents(node: SkillsTreeNode) {
    if (node.parent) {
      const descendants = this.skillstreeControl.getDescendants(node.parent);
      node.parent.isChecked =
        descendants.every(child => child.isChecked);
      node.parent.indeterminate =
        descendants.some(child => child.isChecked) && !node.parent.isChecked;
      this.checkAllParents(node.parent);
    }
  }
  private updateParentCheckboxStates(node: SkillsTreeNode) {
    if (node.parent) {
      this.skillscheckboxStates[node.parent.skillsExpertiseNm] = false;
      this.updateParentCheckboxStates(node.parent);
    }
  }
  findNodeInNestedTree(nodeToFind: SkillsTreeNode, treeData: SkillsTreeNode[]): SkillsTreeNode | undefined {
    for (const node of treeData) {
      if (node.skillsExpertiseNm === nodeToFind.skillsExpertiseNm) {
        return node;
      }

      if (node.children) {
        const foundNode = this.findNodeInNestedTree(nodeToFind, node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return undefined;
  }

  // Function to update all children of a node in the nested tree structure
  updateChildrenRecursively(node: SkillsTreeNode, checked: boolean) {
    this.skillscheckboxStates[node.skillsExpertiseNm] = checked;
    node.isChecked = checked;
    if (node.children && node.disableSecSkillsFlag===0) {
      node.children.forEach(child => 
        {if(child.disableSecSkillsFlag===0 )
        {
        this.updateChildrenRecursively(child, checked);
      }
    }
    );
    }
  }


  private expandFilteredNodes(nodes: SkillsTreeNode[]): void {
    nodes.forEach(node => {
      this.expandNodeRecursive(node);
    });
  }

  private expandNodeRecursive(node: SkillsTreeNode): void {
    if (node.children) {
      this.skillstreeControl.toggle(node);
      // this.skillstreeControl.toggleDescendants(node);
      node.expanded = true;
      node.children.forEach(child => {
        this.expandNodeRecursive(child);
      });
    }
  }

  private filterTreeData(data: SkillsTreeNode[], filterValue: string): SkillsTreeNode[] {
    const filteredNodes: SkillsTreeNode[] = [];
    data.forEach(node => {
      const clonedNode: SkillsTreeNode = { ...node };
      if (clonedNode.children) {
        clonedNode.children = this.filterTreeData(clonedNode.children, filterValue);
      }

      if (clonedNode.skillsExpertiseNm.toLowerCase().includes(filterValue.toLowerCase()) || clonedNode.children.length > 0) {
        // clonedNode.isChecked = this.checkboxStates[node.skillsExpertiseNm];
        //  clonedNode.isChecked = node.isChecked;
        clonedNode.indeterminate = node.indeterminate;
        this.skillstreeControl.expand(node);
        filteredNodes.push(clonedNode);
      }
      // this.checkAllParents(node);
    });

    return filteredNodes;

  }


  toggleRecursive(node: SkillsTreeNode): void {
    // this.skillstreeControl.

    node.expanded = !node.expanded;
    if (node.children) {
      node.children.forEach(child => this.toggleRecursive(child));
    }
  }
  // Skill search 
  skillsSearchFilter(filterText: string): void {
    // this.filter(filterText);
    // show / hide based on state of filter string
    if (!filterText) {
      this.skillstreeControl.collapseAll();
    }
    //this.filterNodes();
  }


  expandedNodes: Set<SkillsTreeNode> = new Set<SkillsTreeNode>();

  private getSelectedNodes(data: SkillsTreeNode[]): SkillsTreeNode[] {
    const selectedNodes: SkillsTreeNode[] = [];
    data.forEach(node => {
      if (node.isChecked) {
        selectedNodes.push(node);
      }
      if (node.children) {
        const childSelectedNodes = this.getSelectedNodes(node.children);
        selectedNodes.push(...childSelectedNodes);
      }
    });
    return selectedNodes;
  }

  public skillsFilterNodes(filterText: string) {
    let filteredTreeData: SkillsTreeNode[];
    if (filterText) {

      filteredTreeData = this.filterTreeData(this.SkillsTree_Data, filterText);
      this.skillstreeControl.dataNodes = filteredTreeData;

      this.skillstreeControl.expansionModel.clear();

      //this.expandFilteredNodes(filteredTreeData);

      this.skillstreeControl.dataNodes.forEach(node => {
        this.expandSkillsFilteredNode(node, filterText);
      });
      this.skillnodeExpansionalstate(filteredTreeData);
      this.skillsdataSource.data = filteredTreeData;
      Object.keys(this.skillsdataSource.data).forEach((key: any) => {
        this.setParent(this.skillsdataSource.data[key]);
      });

    }
    else {
      this.synchronizeSkillsCheckboxStates(this.SkillsTree_Data, this.skillscheckboxStates);
      filteredTreeData = this.SkillsTree_Data;
      this.skillstreeControl.dataNodes = this.SkillsTree_Data;
      this.skillsdataSource.data = this.SkillsTree_Data;
      Object.keys(this.skillsdataSource.data).forEach((key: any) => {
        this.setParent(this.skillsdataSource.data[key]);
      });

      //let resl: SkillsTreeNode[] = []; 
      //for (let i = 0; i < this.skillsCheckedNodes.length; i++) {
      //  this.skillsdataSource.data.forEach(node => {
      //    resl = resl.concat(
      //      this.skillstreeControl
      //        .getDescendants(node)
      //        .filter(x => x.skillsExpertiseNm == this.skillsCheckedNodes[i].skillsExpertiseNm).map(x => x)
      //    );
      //  });
      //}


      //for (let i = 0; i < resl.length; i++) {
      //  this.Toggle(true, resl[i])
      //}
      // this.SkillsTree_Data.forEach(node => { this.checkAllParents(node) });

    }


  }

  synchronizeSkillsCheckboxStates(data: SkillsTreeNode[], selectedNodes: { [key: string]: boolean }): void {

    data.forEach(node => {
      if (selectedNodes.hasOwnProperty(node.skillsExpertiseNm)) {

        node.isChecked = selectedNodes[node.skillsExpertiseNm];
      }

      if (node.children) {

        this.synchronizeSkillsCheckboxStates(node.children, selectedNodes);

      }
      this.checkAllParents(node);
    });

  }


  private skillnodeExpansionalstate(d: SkillsTreeNode[]): boolean {

    d.forEach(node => {
      //if (node.expanded) {
      this.skillstreeControl.expansionModel.select(node);
      if (node.children) {

        this.skillnodeExpansionalstate(node.children);
      }
      //}
    });
    return true;
  }
  private expandSkillsFilteredNode(node: SkillsTreeNode, filterValue: string): void {
    if (node.children) {
      node.expanded = node.children.some(child => child.expanded || child.skillsExpertiseNm.toLowerCase().includes(filterValue));
      node.children.forEach(child => {
        this.expandSkillsFilteredNode(child, filterValue);
      });
    }
  }

  //Devi's code for skills treeview filter'


  filterParentNode(node: SkillsTreeNode): boolean {


    var parentNameMatching = true;
    /*var parent = node.parent;
    while (parent) {
      if ((parent.skillsExpertiseNm.toLowerCase().indexOf(this.skillsSearchString.toLowerCase()) === -1) == false) {
        parentNameMatching = false;
      }
      if (parentNameMatching == false) {
        this.skillstreeControl.expand(parent);
      }
      parent = parent.parent;
    }*/

    if ((node.skillsExpertiseNm.toLowerCase().indexOf(this.skillsSearchString.toLowerCase()) === -1 /*&& parentNameMatching*/) === false) {
      //if (expandaction) {
      //this.skillstreeControl.expansionModel.toggle(node);
      //this.skillstreeControl.toggleDescendants(node);

      // }

      return false;
    }
    const descendants = this.skillstreeControl.getDescendants(node);
    // this.skillstreeControl.expansionModel.clear();
    //  this.check(descendants);

    if (node.children) {

      if (descendants.some((descendantNode) => descendantNode.skillsExpertiseNm.toLowerCase()
        .indexOf(this.skillsSearchString.toLowerCase()) !== -1)) {
        //  if (expandaction) {
        this.skillstreeControl.expansionModel.toggle(node);
        this.skillstreeControl.expand(node);
        //  this.skillstreeControl.toggleDescendants(node);
        // this.expandFilteredNodes(descendants);

        this.cdRef.detectChanges();
        //  }
        return false;

      }

      return true;

    }
    else {

      return true;
    }

    /* if (node.children || node.children2) {*/
    //if (this.tempSkillSearch == "" || this.tempSkillSearch != this.skillsSearchString) {
    //  this.tempSkillSearch = this.skillsSearchString;
    //  console.log("temp: " + this.tempSkillSearch);
    //console.log("this is called");

  }
  //end

  ReadonlyNomineeSkillsnAOE() {
    return ((this.isNominee && !(this.isDraft || this.isQADCertified || this.isEQADCertified)) || this.disableReviewerAOEandSkills || this.isReactivebtnVisible || this.isReadOnly) ? false : true;
 }
  get disableReviewerAOEandSkills() {
    let disableAOEnSkills = false;
    if (this.isReviewer && (( this.FormStatus === 'Draft' && !this.EQADNominateFlag ) || this.FormStatus === 'On Hold' || this.FormStatus === 'Cancelled')) {
      disableAOEnSkills = true;
    }
    return disableAOEnSkills;
  }
  
  get isDraft() {
    return (this.FormStatus === 'Draft') ? true : false;
  }

  get isReadyForQLReview() {
    if (this.QADFormFlag) {
      this.QADFormSubmittedFlag = (this.FormStatus === 'Ready for QL Review') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.QADFormSubmittedFlag = (this.previousFormStatus === 2) ? true : false;
      }

    }
    return this.QADFormSubmittedFlag;
  }

  get isReadyForSCReview() {
    if (this.QADFormFlag) {
      this.QLApprovedFlag = (this.FormStatus === 'Ready for SC Review') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.QLApprovedFlag = (this.previousFormStatus === 3) ? true : false;
      }
    }
    return this.QLApprovedFlag;
  }

  get isReadyForCredentialing() {
    if (this.QADFormFlag) {
      this.QADCredentialingFlag = (this.FormStatus === 'Ready for Certification') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.QADCredentialingFlag = (this.previousFormStatus === 4) ? true : false;
      }
    }
    return this.QADCredentialingFlag;
  }

  get isQADCertified() {
    if (this.QADFormFlag) {
      this.QADCerfiedFlag = (this.FormStatus === 'Certified') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.QADCerfiedFlag = (this.previousFormStatus === 5) ? true : false;
      }
    }
    return this.QADCerfiedFlag
  }
  get isOnHold(): boolean {
    return (this.FormStatus === 'On Hold' ? true : false);
  }
  get isCancelled(): boolean {
    return (this.FormStatus === 'Cancelled' ? true : false);
  }
  get isEQADReadyForQLReview() {
    if (this.EliteQADFormFlag) {
      this.EQADFormSubmittedFlag = (this.FormStatus === 'Ready for QL Review') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.EQADFormSubmittedFlag = (this.previousFormStatus === 2) ? true : false;
      }
    }

    return this.EQADFormSubmittedFlag;
  }

  get isEQADReadyForSCReview() {
    if (this.EliteQADFormFlag) {
      this.EQADQLApprovedFlag = (this.FormStatus === 'Ready for SC Review') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.EQADQLApprovedFlag = (this.previousFormStatus === 3) ? true : false;
      }
    }
    return this.EQADQLApprovedFlag;
  }

  get isEQADReadyForCertification() {
    if (this.EliteQADFormFlag) {
      this.EQADCredentialingFlag = (this.FormStatus === 'Ready for Certification') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.EQADCredentialingFlag = (this.previousFormStatus === 4) ? true : false;
      }
    }
    return this.EQADCredentialingFlag;
  }
  get isEQADDraft() {
    return (this.FormStatus === 'Draft' && this.EliteQADFormFlag) ? true : false;

  }


  get isEQADCertified() {
    if (this.EliteQADFormFlag) {
      this.EQADCertifiedFlag = (this.FormStatus === 'Certified') ? true : false;
      if (this.FormStatus === "On Hold" || this.FormStatus === "Cancelled") {
        this.EQADCertifiedFlag = (this.previousFormStatus === 5) ? true : false;
      }
    }
    return this.EQADCertifiedFlag
  }

  get isSavebtnVisible(): boolean {
 
      let btnsave = true;
      if (this.isAdmin) {
        if ((this.isDraft && this.QADFormFlag) || this.FormStatus === 'On Hold' || this.FormStatus === 'Cancelled' || (this.isEQADDraft && this.isNoupdatesneededbtnVisible)) {
          btnsave = false;
        }
      }
      if (this.isReviewer) {

        if ((this.FormStatus === 'Draft' && this.QADFormFlag) || this.FormStatus === 'Ready for QL Review' || this.FormStatus === 'On Hold' || this.FormStatus === 'Cancelled' || (this.isEQADDraft && !this.EQADNominateFlag)) {

          btnsave = false;
        }
      }
      if (this.isNominee) {
        if (this.FormStatus === 'Draft' || this.FormStatus === 'Ready for QL Review' || this.FormStatus === 'Ready for SC Review' || this.FormStatus === 'Ready for Certification' || this.FormStatus === 'On Hold' || this.FormStatus === 'Cancelled') {
          btnsave = false;
        }
      }
      if (this.isReadOnly) {
        btnsave = false;
      }
    
    return btnsave;
  }

  get isReactivebtnVisible(): boolean {

    let btnReactivate = false;

    if (this.isAdmin && (this.FormStatus === 'On Hold' || this.FormStatus === 'Cancelled')) {
      btnReactivate = true;
    }
    return btnReactivate;


  }
  get isStreeCoDecisionEnabled(): boolean {
    return (this.isAdmin && !this.disablesteercoDateandDecison) || (this.isAdmin && !this.isReactivebtnVisible);
  }

  get disableRegistrationCancelledDate(): boolean {
    let disbleregistrationcanceldt = true;
    if (this.StreeCoDecisionsselected === 2 || this.StreeCoDecisionsselected === 3) {
      disbleregistrationcanceldt = false;
    }
    return disbleregistrationcanceldt;
  }

  get disabledfornominationtextarea(): boolean {
    let rationaltextarea = true;
    if (this.isAdmin && (this.isReadyForQLReview || this.isEQADReadyForQLReview || ( this.isEQADDraft && this.EQADNominateFlag))) {
      rationaltextarea = false;
    }
    else if (this.isReviewer && (this.isReadyForQLReview || this.isEQADReadyForQLReview || ( this.isEQADDraft && this.EQADNominateFlag))) {
      rationaltextarea = false;
    }
    return rationaltextarea;
  }

  get isSubmitbtnVisible(): boolean {
    let submitbtnvisible = false;
    if (this.isAdmin && this.isDraft && !this.EQADNominateFlag) {
      submitbtnvisible = true
    }
    else if (this.isNominee && this.isDraft && !this.isNoupdatesneededbtnVisible) {
      submitbtnvisible = true
    }
    return submitbtnvisible;
  }

  get isNoupdatesneededbtnVisible(): boolean {
    this.btnnopudatestooltipClass = 'custom-tooltip';
    let Noupdatesbtnvisible = false;
    const selectedAOEIds = this.AOEData.some(item => item.AOECheckInd);
    const selectedSkillsIds = (this.getselectedSkillsIds().length > 0);
    const currentAOEIds = this.getSelectedAOEIds();  
    const currentSkillsIds = this.getselectedSkillsIds();
    const currAOEIdsArray = currentAOEIds ? currentAOEIds.split(',').map(id => id.trim()).sort() : [];
    const prevAOEIdsArray = this.previousAOEIds ? this.previousAOEIds.split(',').map(id => id.trim()).sort() : [];
    const currSkillsIdsArray = currentSkillsIds ? currentSkillsIds.split(',').map(id => id.trim()).sort() : [];
    const prevSkillsIdsArray = this.previousSkillsIds ? this.previousSkillsIds.split(',').map(id => id.trim()).sort() : [];

    const AOEFlag = currAOEIdsArray.length === prevAOEIdsArray.length && prevAOEIdsArray.every((val, index) => val === currAOEIdsArray[index]);
    const SkillFlag = currSkillsIdsArray.length === prevSkillsIdsArray.length && prevSkillsIdsArray.every((val, index) => val === currSkillsIdsArray[index]);
   
    if (this.EQADNominateFlag) {
      if (this.isEQADDraft && AOEFlag && !!this.NominatorsEnterprise && this.noupdatesbtnvisiblecheck && (this.isAdmin || this.isNominee || this.isReviewer)) {

        Noupdatesbtnvisible = true
      }
     
    }
    return Noupdatesbtnvisible;
  }

  get isNominateForEQADbtnVisible(): boolean {
    let NominateForEQADbtnVisible = false;
    if ((this.isAdmin) && this.QADFormFlag && !this.EliteQADFormFlag) {
      NominateForEQADbtnVisible = true;
    }
    else if ((this.isReviewer) && this.QADFormFlag && !this.EliteQADFormFlag) {
      NominateForEQADbtnVisible = (this.FormStatus === 'Certified') ? true : false;
    }
    return NominateForEQADbtnVisible;
  }

  get isApprovebtnVisible(): boolean {
    let approvebtnflag = false;
    if ((this.isReviewer || this.isAdmin) && this.FormStatus === 'Ready for QL Review') {
      approvebtnflag = true;
    }
    return approvebtnflag;
  }

  onAutocompleteOpened(): void {
    this.isAutocompleteOpen = true;
    const cdkOverlayContainer = document.querySelector('.cdk-overlay-container');

    // Check if cdk-overlay-container is found
    if (cdkOverlayContainer) {
      cdkOverlayContainer.classList.add("checkcdkoverlaycontainer");
      cdkOverlayContainer.classList.remove("cdk-overlay-container");    
    } 
  }

  onAutocompleteClosed(): void {

    this.isAutocompleteOpen = false;
    const checkcdkOverlayContainer = document.querySelector('.checkcdkoverlaycontainer');

    // Check if cdk-overlay-container is found
    if (checkcdkOverlayContainer) {
      checkcdkOverlayContainer.classList.remove("checkcdkoverlaycontainer");
      checkcdkOverlayContainer.classList.add("cdk-overlay-container");
    } 
    this.NominatorEIDData = [];
   
  }
  //Application timeout logic start


  private resetapplicationTimer(): void {
    localStorage.setItem('lastActivityTime', Date.now().toString());
  }

  private startapplicationActivityCheck(): void {
    setInterval(() => {
      this.checkAppTimeoutActivity();
    }, this.ACTIVITY_CHECK_INTERVAL);
  }
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keypress', ['$event'])
  onUserInteraction(event: MouseEvent | KeyboardEvent): void {
    // Restart the timeout whenever there's user activity
    this.onUserActivity();
  }
  private checkAppTimeoutActivity(): void {
    const currentTime = Date.now();
    const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime') || '0', 10);
    const timeSinceLastActivity = currentTime - lastActivityTime;
    if (timeSinceLastActivity >= this.INACTIVITY_TIMEOUT) {
      console.log('User inactive for too long. Perform timeout actions.');
      const logoutLink = document.getElementById('btnlogout');
      if (logoutLink) {
        this.renderer.selectRootElement(logoutLink).click();
      }
      this.rebarAuthService.applogout();
    }
  }

  public onUserActivity(): void {
    localStorage.setItem(this.logoutKey, 'false');
    // Call this method whenever there is user activity
    this.resetapplicationTimer();
  }
  private startTokenCheck() {
    setInterval(() => {
      const logoutkeyvalue = localStorage.getItem('logoutKey');
      if (logoutkeyvalue == 'true' || this.rebarAuthService.isLoggedOut()) {
        const accounts = this.auth.instance.getAllAccounts();
        this.auth.instance.setActiveAccount(accounts[0]);

        const logoutRequest = {
          account: this.auth.instance.getAllAccounts()[0],
        };
        this.rebarAuthService.logoutRedirect(logoutRequest);
      }
    }, this.checkInterval);
  }
  applicationLogout() {
    this.rebarAuthService.applogout();
  }
  private setupStorageListener(): void {
    // Listen for changes in localStorage (cross-window communication)
    window.addEventListener('storage', (event) => {
      if (event.key === 'logoutKey') {
        const logoutkeyvalue = localStorage.getItem('logoutKey');
        if (logoutkeyvalue == 'true') {
          this.rebarAuthService.applogout();
        }
      }
    });
  }
  //Application timeout logic ends
}


