import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, Injector, Input } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';
import { FormControl } from '@angular/forms';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AdminPageComponent } from '../admin-page.component';
import { IRoleLevelAccess } from 'src/app/core/models/RoleLevelAccess';
interface Nominee {
  EnterpriseId: string;
  IsQADContractorFlag: number;
}
@Component({
  selector: 'app-createnominationform',
  templateUrl: './createnominationform.component.html',
  styleUrls: ['./createnominationform.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CreatenominationformComponent implements OnInit {
  currentusername: any = '';
  isAdmin = false;
  isReviewer = false;
  userRole!: IRoleLevelAccess;
  isModalOpen: boolean = false;
  NomineesEnterprise: string = "";
  public NominatorsEnterprise: string = "";
  public Rational: string = "";
  public NomineeData: any = "";
  public NominatorData: any = "";
  public NomineeEIDData: any = "";
  public NominatorEIDData: any = "";
  public lblNomineeMarket: any = "";
  public lblNominatorMarketUnit: any = "";
  public lblNomineeMarketUnit: any = "";
  public MarketUnitQRLEnterpriseID: any = "";
  public HeldPriorCert: any = "";
  public NominatorPeopleKey: any = "";
  public currentUserName: any = "";
  public FormLink: any = "";
  public FormExist: any = "";
  public PeopleKeyQRL: any = "";
  public NewFormPopup: any = null;
  public prevLastNom: any = "";
  public nominatortext: any = "";
  public lblExist: any = "";
  NominatorEIDs: any[] = [];
  public nomineetext: any = "";
  public ValidNomineeEID: any = "";
  public InValidNominatorEID: any = "";
  public i: any = "";
  public lblWarnings: any = "";
  public lblWarning: any = "";
  public NominatorsMarketToolTip: any = "";
  myControl = new FormControl('');
  public selectedNominee: any = "";
  public selectedNominator: any = "";
  public _adminPage: AdminPageComponent = this._injector.get<AdminPageComponent>(AdminPageComponent);
  public dropNomineeIndustrySegment: { NomineeIndustrySegment: string, MUQLEnterpriseId: string ,MUIndustrySegementId:number}[] = [];
  public SelectedIndustrySegment: string = "";
  public IndustryData: any = "";
  public MUIndustrySegementId: any = 0;

  NominatorCtrl = new FormControl();
  @ViewChild('createNominationModal') public createNominationModal!: NgbPopover;
  @ViewChild('NominatorInput') NominatorInput!: ElementRef<HTMLInputElement>;
  disablecreateQADbtn = false;

  ngOnInit(): void {
    this.rebarAuthService.refreshToken();
    this.currentusername = this.commonService.getCurrentUsername();
    this.getCurrentUserRole();
    this.lblExist = "A form already exists for the EID.";
    this.commonService.checkUserRole().subscribe((usr: string) => {

      if (usr === "ReadOnly") {
        this.disablecreateQADbtn = true;
      }
    });

    this.fetchNomineeIndustrySegment();
   
  }

  async getCurrentUserRole() {
    const paramEID = { EID: this.currentusername };
    await this.commonService.getCurrentUserRole(paramEID).subscribe((response: any) => {
      this.userRole = response.find((item: any) => item.EnterpriseId === this.currentusername);
      // console.log("Filtered User Role:", this.userRole); // Debugging
      if (this.userRole) {
        const { RoleDesc } = this.userRole;
        if (RoleDesc === 'Administrator') {
          this.isAdmin = true;
        }else if (RoleDesc === 'Reviewer') {
          this.isReviewer = true;
        }
        // console.log("User Role Set:", RoleDesc);
        //   console.log("isAdmin:", this.isAdmin, "isReviewer:", this.isReviewer,);
      }
    });
  }
  
  fetchNomineeIndustrySegment() {
this.commonService.getNomineeIndustrySegment().subscribe(
      (response: any) => {


        this.dropNomineeIndustrySegment = response.map((data: any) => ({
          NomineeIndustrySegment: data["NomineeIndustrySegment"],
          MUQLEnterpriseId: data["MUQLEnterpriseId"],
          MUIndustrySegementId: data["MUId"]
        }));

      },
      (error) => {
        console.error('Error fetching industry segment:', error);
      }
    );
  }
  onIndustrySegmentSelected(event: any) {
    const qadbtn = document.getElementById('CreateQAD') as HTMLButtonElement;
    const eqadbtn = document.getElementById('CreateEQAD') as HTMLButtonElement;
    const selectedIndustry = event.value;
if (selectedIndustry && selectedIndustry.MUQLEnterpriseId) {
      this.MarketUnitQRLEnterpriseID = selectedIndustry.MUQLEnterpriseId;
      this.MUIndustrySegementId = selectedIndustry.MUIndustrySegementId;
    }
    this.SelectedIndustrySegment = selectedIndustry;
    if (this.ValidNomineeEID == 1 && this.NominatorData != null && this.InValidNominatorEID == null && this.SelectedIndustrySegment) {
      qadbtn.disabled = false;
      eqadbtn.disabled = false;
    }
  }

  constructor(private modalService: NgbModal, private commonService: CommonService, private rebarAuthService: RebarAuthService, private router: Router, private route: ActivatedRoute, private _injector: Injector) {

  }

    prevSelectedNominatorsEIDArr: string[] = [];
    prevSelectedNominatorsEIDstr: string = "";
    selected(event: MatAutocompleteSelectedEvent): void {
      if(this.prevSelectedNominatorsEIDArr.length > 0 && this.NominatorEIDs.length == 1) {
      const lastValueOfNominatorEIDs = this.prevSelectedNominatorsEIDArr[this.prevSelectedNominatorsEIDArr.length - 1];
      const isLastValuePresentInnameArr = this.NominatorEIDs.some(value => value === lastValueOfNominatorEIDs);

      if (isLastValuePresentInnameArr) {
        this.NominatorEIDs = this.prevSelectedNominatorsEIDArr.map((value: any) => value.toString());
      }
    }

    if ((this.NominatorEIDs).includes(event.option.viewValue) == false) {
      this.NominatorEIDs.push(event.option.viewValue);
      this.NominatorsEnterprise = (this.NominatorEIDs).join(',');
    }
    this.NominatorEIDs = Array.from(new Set(this.NominatorEIDs.map(e => e.trim()))); //Remove duplicate values
    this.prevSelectedNominatorsEIDArr = this.NominatorEIDs;
    this.prevSelectedNominatorsEIDstr = (this.NominatorEIDs).join(',');
    this.NominatorCtrl.setValue(this.prevSelectedNominatorsEIDstr);
    this.NominatorsEnterprise = (this.NominatorEIDs).join(',');
  }

  select(event: MatAutocompleteSelectedEvent): void {
    this.selectedNominee = "true";
  }
  openVerticallyCentered(content: any) {
    this._adminPage.popover.close();
    this._adminPage.popOverSelectColumn.close();
    this.isModalOpen = true;
    if (this.NewFormPopup) {
      this.NewFormPopup.dismiss();
      this.NewFormPopup = this.modalService.open(content, { windowClass: "popupwindowtemplate", centered: true });
    }
    else {
      this.NewFormPopup = this.modalService.open(content, { windowClass: "popupwindowtemplate", centered: true });
    }
    this.disablebuttons();
  }
  disablebuttons() {
    const qadbtn = document.getElementById('CreateQAD') as HTMLButtonElement;
    const eqadbtn = document.getElementById('CreateEQAD') as HTMLButtonElement;
    this.NomineesEnterprise = "";
    this.NominatorsEnterprise = "";
    this.Rational = "";
    this.lblNomineeMarket = "";
    this.lblNominatorMarketUnit = "";
    this.SelectedIndustrySegment = "";
    this.lblNomineeMarketUnit = "";
    this.MarketUnitQRLEnterpriseID = "";
    this.HeldPriorCert = "";
    this.FormLink = "";
    this.FormExist = "";
    this.NominatorPeopleKey = "";
    this.PeopleKeyQRL = "";
    this.NomineeEIDData = null;
    this.NominatorEIDData = null;
    this.NominatorData = null;
    this.NomineeData = null;
    qadbtn.disabled = true;
    eqadbtn.disabled = true;
  }

  closeVerticallyCentered() {
    this.isModalOpen = false;
    this.NewFormPopup.close();
  }

  async onFocusOutNomineeEvent(event: any) {
    const qadbtn = document.getElementById('CreateQAD') as HTMLButtonElement;
    const eqadbtn = document.getElementById('CreateEQAD') as HTMLButtonElement;
    const lblFormExists = document.getElementById('lblformexist') as HTMLLabelElement;
    const lnkFormExist = document.getElementById('linkformexist') as HTMLLinkElement;
    const txtboxnominee = document.getElementById('NomineepeopleEID') as HTMLTextAreaElement;
    const nomineeEID = { NomineeEnterpriseId: event.target.value, EID: this.currentUserName };
    if (this.NomineesEnterprise != "") {
      await this.commonService.getNomineeDetails(nomineeEID).subscribe((response: any) => {
        this.NomineeData = response;
        this.ValidNomineeEID = (this.NomineeData)[0].NomineeEIDValidityFlag;
        if (this.ValidNomineeEID == 1) {
          this.FormExist = (this.NomineeData)[0].FormExist;
          if (this.FormExist == 0) {
            this.lblNomineeMarket = (this.NomineeData)[0].NomineeMarket;
            this.lblNomineeMarketUnit = (this.NomineeData)[0].NomineeMarketUnit;
// Check if Market Unit is "United States"
            if (this.lblNomineeMarketUnit === "United States") {
              this.MarketUnitQRLEnterpriseID = ""; // Leave empty initially
            } else {
              this.MarketUnitQRLEnterpriseID = (this.NomineeData)[0].MarketUnitQRLEnterpriseID;
            }
            this.PeopleKeyQRL = (this.NomineeData)[0].PeopleKeyQRL;
            this.HeldPriorCert = (this.NomineeData)[0].HeldPriorQADCertification;
            lblFormExists.style.display = 'none';
            lnkFormExist.style.display = 'none';
            txtboxnominee.style.borderColor = '#767676';

            if (this.ValidNomineeEID == 1 && this.NominatorData != null && this.InValidNominatorEID == null && this.lblNomineeMarketUnit != "United States") {
              qadbtn.disabled = false;
              eqadbtn.disabled = false;
            }
            else if (this.ValidNomineeEID == 1 && this.NominatorData != null && this.InValidNominatorEID == null && this.lblNomineeMarketUnit === "United States" && this.SelectedIndustrySegment) {
              qadbtn.disabled = false;
              eqadbtn.disabled = false;
}
            else {
              qadbtn.disabled = true;
              eqadbtn.disabled = true;
            }
          }
          else {
            qadbtn.disabled = true;
            eqadbtn.disabled = true;
            this.FormLink = (this.NomineeData)[0].FormLink;
            lblFormExists.style.display = 'block';
            this.lblWarnings = this.lblExist;
            lblFormExists.style.marginTop = '0px';
            lnkFormExist.style.display = 'block';
            txtboxnominee.style.borderColor = 'red';
          }
        }
        else {
          qadbtn.disabled = true;
          eqadbtn.disabled = true;
          this.lblNomineeMarket = "";
          this.lblNomineeMarketUnit = "";
          this.SelectedIndustrySegment = "";
          this.HeldPriorCert = "";
          this.MarketUnitQRLEnterpriseID = "";
          if (this.selectedNominee == "true") {
            lblFormExists.style.display = 'none';
            lnkFormExist.style.display = 'none';
          }
          else {
            lblFormExists.style.display = 'block';
            this.lblWarnings = "EID is not valid.";
            lblFormExists.style.marginTop = '-8px';
            lnkFormExist.style.display = 'none';
          }
        }
      });
    }
    this.selectedNominee = "false";
  }

  async onFocusOutNominatorEvent(event: any) {
    const qadbtn = document.getElementById('CreateQAD') as HTMLButtonElement;
    const eqadbtn = document.getElementById('CreateEQAD') as HTMLButtonElement;
    const lblFormWarning = document.getElementById('lblformwarning') as HTMLLabelElement;
    if (this.NominatorsEnterprise == "" || this.NominatorsEnterprise == null || this.NominatorsEnterprise == undefined) {
      lblFormWarning.style.display = 'none';
      lblFormWarning.style.marginTop = '0px';
      this.NominatorEIDData = [];
    }
    const txtboxnominator = document.getElementById('NominatorpeopleEID') as HTMLTextAreaElement;
    let nominatorid = event.target.value;
    nominatorid = nominatorid.replace(/,*$/, '');
    if (event.target.value != "") {
      const nominatorEID = { NominatorEnterpriseId: nominatorid, EID: this.currentUserName };
      await this.commonService.getNominatorDetails(nominatorEID).subscribe((response: any) => {
        this.NominatorData = response;
        this.InValidNominatorEID = this.NominatorData[0].NominatorInvalidEID;
        if (this.InValidNominatorEID == null) {
          lblFormWarning.style.display = 'none';
          lblFormWarning.style.marginTop = '0px';
          this.NominatorEIDData = [];
          this.lblNominatorMarketUnit = this.NominatorData[0].NominatorMarketUnit;
          if ((this.lblNominatorMarketUnit).length > 35) {
            this.NominatorsMarketToolTip = this.lblNominatorMarketUnit;
          }
          else {
            this.NominatorsMarketToolTip = "";
          }
          this.NominatorPeopleKey = this.NominatorData[0].NominatorPeopleKey;
        }
        else {
          this.lblNominatorMarketUnit = "";
          qadbtn.disabled = true;
          eqadbtn.disabled = true;
        }
        if (this.ValidNomineeEID == 1 && this.InValidNominatorEID == null && this.FormExist == 0 && this.NomineeData != null && this.lblNomineeMarketUnit != "United States") {
          qadbtn.disabled = false;
          eqadbtn.disabled = false;
        }
        else if (this.ValidNomineeEID == 1 && this.NominatorData != null && this.InValidNominatorEID == null && this.lblNomineeMarketUnit === "United States" && this.SelectedIndustrySegment) {
          qadbtn.disabled = false;
          eqadbtn.disabled = false;
        }
        else {
          qadbtn.disabled = true;
          eqadbtn.disabled = true;
        }
      });
    }
    else {
      lblFormWarning.style.display = 'none';
      lblFormWarning.style.marginTop = '0px';
      this.NominatorEIDData = [];
    }
    this.NominatorsEnterprise = nominatorid;


  }

  async NomineeChangeFn() {
      const qadbtn = document.getElementById('CreateQAD') as HTMLButtonElement;
      const eqadbtn = document.getElementById('CreateEQAD') as HTMLButtonElement;
      const lblFormExists = document.getElementById('lblformexist') as HTMLLabelElement;
      const lnkFormExist = document.getElementById('linkformexist') as HTMLLinkElement;
      const txtboxnominee = document.getElementById('NomineepeopleEID') as HTMLTextAreaElement;
      if (this.NomineesEnterprise == "") {
        this.lblNomineeMarket = "";
        this.lblNomineeMarketUnit = "";
        this.MarketUnitQRLEnterpriseID = "";
        this.PeopleKeyQRL = "";
        this.FormExist = "";
        this.FormLink = "";
        this.HeldPriorCert = "";
        this.SelectedIndustrySegment = "";
        lblFormExists.style.display = 'none';
        lnkFormExist.style.display = 'none';
        txtboxnominee.style.borderColor = '#767676';
        qadbtn.disabled = true;
        eqadbtn.disabled = true;
      }
      else {
        lblFormExists.style.display = 'none';
        lnkFormExist.style.display = 'none';
        txtboxnominee.style.borderColor = '#767676';
        const nomineetext = { Eid: this.NomineesEnterprise, CurrentEID: this.currentUserName };
        await this.commonService.getNomineeEID(nomineetext).subscribe((response: any) => {
          this.NomineeEIDData = response;
          this.selectedNominee = this.NomineeEIDData.find(
          (nominee: any) => nominee.EnterpriseId === this.NomineesEnterprise
          );
        });
      }
    }

  async NominatorChangeFn(event: any) {
    const qadbtn = document.getElementById('CreateQAD') as HTMLButtonElement;
    const eqadbtn = document.getElementById('CreateEQAD') as HTMLButtonElement;
    const lblFormWarning = document.getElementById('lblformwarning') as HTMLLabelElement;
    if (this.NominatorsEnterprise == "" || this.NominatorsEnterprise == null || this.NominatorsEnterprise == undefined) {
      lblFormWarning.style.display = 'none';
      lblFormWarning.style.marginTop = '0px';
      qadbtn.disabled = true;
      eqadbtn.disabled = true;
      this.lblNominatorMarketUnit = "";
      this.NominatorPeopleKey = "";
      this.NominatorEIDData = [];
    }
    const lastVal = this.NominatorsEnterprise.slice(-1);    
    var namedArr = this.NominatorsEnterprise.split(',');
    let val = "";
    const currentLastNom = namedArr[(namedArr.length) - 1];    
    if (this.NominatorEIDs.length > 0) {
      namedArr = this.NominatorEIDs;
      if (namedArr.indexOf(currentLastNom) >= 0) { val = "true" }
    }
    if (lastVal == ',' || currentLastNom == this.prevLastNom || val == "true") {
      const hidePop = document.querySelector('.cdk-overlay-pane');
      hidePop?.classList.add('remove');
      hidePop?.classList.remove('cdk-overlay-pane');
      this.NominatorEIDData = [];
      const nominator = { NominatorEnterpriseId: this.NominatorsEnterprise, EID: this.currentUserName };
      await this.commonService.getNominatorDetails(nominator).subscribe((response: any) => {
        this.NominatorData = response;
        this.InValidNominatorEID = this.NominatorData[0].NominatorInvalidEID;
        if (this.InValidNominatorEID == null) {
          lblFormWarning.style.display = 'none';
          lblFormWarning.style.marginTop = '0px';
        }
      });
    }
    else {      
      if (this.NominatorsEnterprise == "") {
        lblFormWarning.style.display = 'none';
        lblFormWarning.style.marginTop = '0px';
        qadbtn.disabled = true;
        eqadbtn.disabled = true;
        this.lblNominatorMarketUnit = "";
        this.NominatorPeopleKey = "";
      }
      else {
        lblFormWarning.style.display = 'none';
        lblFormWarning.style.marginTop = '0px';
        let nominatoreid = this.NominatorsEnterprise;
        nominatoreid = nominatoreid.replace(/,*$/, '');
        var nameArr = nominatoreid.split(',');
        const length = nameArr.length;
        this.prevLastNom = nameArr[length - 1];
        if (length > 1) {
          this.nominatortext = { Eid: nameArr[length - 1], CurrentEID: this.currentUserName };
        }
        else {
          this.nominatortext = { Eid: nominatoreid, CurrentEID: this.currentUserName };
        }
        await this.commonService.getNominatorEID(this.nominatortext).subscribe((response: any) => {
          this.NominatorEIDData = response;
          //if (this.NominatorEIDData.length > 0) {
          //  if (this.NominatorEIDData[0].EnterpriseId == nameArr[length - 1]) {
          //    this.NominatorEIDData = [];
          //    const hidePop = document.querySelector('.cdk-overlay-pane');
          //    hidePop?.classList.add('remove');
          //    hidePop?.classList.remove('cdk-overlay-pane');
          //  }
          //  else {
          //    const hidePops = document.querySelector('.remove');
          //    hidePops?.classList.add('cdk-overlay-pane');
          //    hidePops?.classList.remove('remove');
          //  }
          //}
          //else {
          //  const hidePops = document.querySelector('.remove');
          //  hidePops?.classList.add('cdk-overlay-pane');
          //  hidePops?.classList.remove('remove');
          //}
        });
        const hidePops = document.querySelector('.remove');
        hidePops?.classList.add('cdk-overlay-pane');
        hidePops?.classList.remove('remove');
        if (event.type != "click") {
          const nominator = { NominatorEnterpriseId: nominatoreid, EID: this.currentUserName };
          await this.commonService.getNominatorDetails(nominator).subscribe((response: any) => {
            this.NominatorData = response;
            this.InValidNominatorEID = this.NominatorData[0].NominatorInvalidEID;
            if (this.InValidNominatorEID != null) {
              const invalidnom = this.InValidNominatorEID.split(',');
              for (this.i = 0; this.i < invalidnom.length; this.i++) {
                const ind = nameArr.indexOf(invalidnom[this.i]);
                if (ind > -1) {
                  nameArr.splice(ind, 1);
                }
              }
              lblFormWarning.style.display = 'block';
              lblFormWarning.style.marginTop = '-8px';
              this.lblWarning = "EID is not valid.";
              
            }
            else {
              lblFormWarning.style.display = 'none';
              lblFormWarning.style.marginTop = '0px';
            }
            this.NominatorEIDs = nameArr;
          });
        }
      }
    }
  }


  async createQAD() {
    if (this.Rational.search(';') != -1) {
      this.Rational = this.Rational.replaceAll(';', '¥');
    }

    
    const saveparams = { NomineeEnterpriseId: this.NomineesEnterprise, NominatorPeopleKey: this.NominatorPeopleKey, CertificationId: 2, PeopleKeyQRL: this.PeopleKeyQRL, AdminUser: this.currentUserName, RationaleNotes: this.Rational, EQADExceptionApproved: "", MUIndustrySegementId: this.MUIndustrySegementId  };
    await this.commonService.saveNomineeDetails(saveparams).subscribe((response: any) => {
      this.NomineeData = response;
    });
    //this.grid.refreshCells();
    this.closeVerticallyCentered();
    // this.router.navigate(['/admin']);
    this._adminPage.onGridFilterApplyReset('Apply');
    const routeURL = `/${this.NomineesEnterprise}`;
    window.open(routeURL, '_blank');

  }

  restrictSpecialChar(event: any) {
    var k = event.which || event.keyCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || k > 35 && k < 42 || k > 43 && k < 58 || k === 95 || k === 58 || k === 59 || k === 34 || k === 63) {
      return true;
    }
    else {
      return false;
    }
  }
  restrictAlphabetsAndDot(event: any) {
    const k = event.which || event.keyCode;
    if (
      (k >= 65 && k <= 90) ||
      (k >= 97 && k <= 122) ||
      k === 46 || k === 45
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  restrictAlphabetsCommaDot(event: any) {
    const k = event.which || event.keyCode;
    if (
      (k >= 65 && k <= 90) ||
      (k >= 97 && k <= 122) ||
      k === 46 ||
      k === 44 || k === 45
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  handleExistingLinkClick(event: Event) {
    this.closeVerticallyCentered();
  }
}
