<button class="createnominationform badge badge-primarynewform badge-pill"
        (click)="$event.stopPropagation(); openVerticallyCentered(content)" [hidden]="disablecreateQADbtn">
  <span style="font-size:24px;font-weight:400;position:absolute;top:9px;left:15px">+</span><span style="position:absolute;top:12px;left:35px;">Create New Form</span>
</button>

<ng-template #content let-modal>
  <div class="container-fluid createnominationform-modal">
    <div class="createnominationform-header">
      <div class="createnominationform-title">Create New QAD Form</div>
      <div class="createnominationform-subtitle">You are creating a new QAD Nomination form. Please fill out the Nominee’s Enterprise ID and Nominator’s Enterprise ID(s). If more than one Nominator, separate with a comma.</div>
    </div>


    <div id="divgeneralinfo" style="padding-top: 10px;">
      <div class="row formdivContainer">

        <table class="createformtable">
          <tr>
            <td class="createformdatalabels">
              <label class="row QADMediumFieldLabel">Nominee's Enterprise ID</label>
              <div style="display: flex; align-items: center; gap: 15px;">
              <input type="text" 
                     style="width: 328px; border: 1px solid #767676; height: 32px; border-radius: 3px; font-size: 14px; font-family: 'Graphik-Regular'; color: #4F4B53; font-weight: 500; padding:3px;" 
                     id="NomineepeopleEID" 
                     class="textbox row QADRegularFieldText" 
                     [(ngModel)]="NomineesEnterprise" 
                     (focusout)="onFocusOutNomineeEvent($event)" 
                     (keypress)="restrictAlphabetsAndDot($event)" 
                     (ngModelChange)="NomineeChangeFn()" 
                     #NomineeId matInput 
                     [formControl]="myControl" 
                     [matAutocomplete]="auto">
                     <mat-icon *ngIf="(isAdmin || isReviewer) && (selectedNominee?.IsQADContractorFlag == 1)" 
                     matTooltip="This nominee is a contractor"
                     style="cursor: pointer; color: #767676;">info</mat-icon>
            </div>
              <mat-autocomplete #auto="matAutocomplete" #Nominee id="NomineeEIDInputBox" (optionSelected)="select($event)">
                <mat-option #NomineeOption *ngFor="let item of NomineeEIDData" appen [value]="item.EnterpriseId">
                  {{item.EnterpriseId}}
                </mat-option>
              </mat-autocomplete>
            </td>
            
            <td class="createformdatalabels" style="padding-left: 60px;">
              <label class="row QADMediumFieldLabel">Nominator's Enterprise ID(s)</label>
              <mat-chip-grid #chipGrid aria-label="Nominator selection">
                <mat-chip-row *ngFor="let item of NominatorEIDs">
                  {{item}}
                </mat-chip-row>
              </mat-chip-grid>
              <input style="width: 328px; margin-left: -14px; border: 1px solid #767676; height: 32px; border-radius: 3px; font-size: 14px;font-family: 'Graphik-Regular'; color: #4F4B53; font-weight: 500; padding:3px;" #NominatorInput id="NominatorpeopleEID" [formControl]="NominatorCtrl" [(ngModel)]="NominatorsEnterprise" (ngModelChange)="NominatorChangeFn($event)" (keypress)="restrictAlphabetsCommaDot($event)" [matChipInputFor]="chipGrid" [matAutocomplete]="autos" (focusout)="onFocusOutNominatorEvent($event)" />


              <mat-autocomplete #autos="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let items of NominatorEIDData" appen [value]="items.EnterpriseId">
                  {{items.EnterpriseId}}
                </mat-option>
              </mat-autocomplete>
            </td>
          </tr>
          <tr>
            <td style="padding-left: 12px;">
              <label id="lblformexist" class="formexist" style="width: 220px;display:none">{{lblWarnings}}</label>
              <a href={{this.FormLink}} id="linkformexist" class="link" target="_blank" (click)="handleExistingLinkClick($event)">Link to existing form</a>
            </td>
            <td style="padding-left: 62px;">
              <label id="lblformwarning" class="formexist" style="width: 220px;">{{lblWarning}}</label>
            </td>
          </tr>
          <tr>
            <td class="createformdatalabels">
              <label class="row QADMediumFieldLabel" style=" width: 328px;">Nominee's Market</label>
              <label class="popuplabelsdata" style="width: 328px; margin-left: -14px">{{lblNomineeMarket}}</label>
            </td>
            <td class="createformdatalabels" style="padding-left: 60px;">
              <label class="row QADMediumFieldLabel" style=" width: 328px;">Nominator's Market Unit(s)</label>
              <label class="popuplabelsdata" style="display: inline-block; max-width: 328px; margin-left: -14px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" [matTooltip]="NominatorsMarketToolTip" matTooltipPosition="right">{{lblNominatorMarketUnit}}</label>
            </td>
          </tr>
          <tr>
            <td class="createformdatalabels">
              <label class="row QADMediumFieldLabel" style=" width: 328px;">Nominee's Market Unit</label>
              <label class="popuplabelsdata" style="width: 328px; margin-left: -14px">{{lblNomineeMarketUnit}}</label>
            </td>
            <td class="createformdatalabels" style="padding-left: 60px;">
              <label class="row QADMediumFieldLabel" style=" width: 328px;">Market Unit Quality Lead</label>
              <label class="popuplabelsdata" style="width: 328px; margin-left: -14px">{{MarketUnitQRLEnterpriseID}}</label>
            </td>
          </tr>
          <!-- Add the Nominee's Industry Segment for Americas field -->
          <tr *ngIf="lblNomineeMarketUnit === 'United States'">
            <td class="createformdatalabels">
              <label class="row QADMediumFieldLabel"  matTooltip="Industry Level 1" 
         [matTooltipPosition]="'above'" matTooltipClass="IStooltip">Nominee's Market Unit - Americas</label>
              <ng-container>
                <mat-form-field style="width: 328px; margin-left: -24px;height: 32px;" id="industryseg">
                  <mat-icon class="expand_icon">expand_more</mat-icon>
                  <mat-select [(ngModel)]="SelectedIndustrySegment" (selectionChange)="onIndustrySegmentSelected($event)" >
                      <mat-option *ngFor="let segment of dropNomineeIndustrySegment" id="segment.MUIndustrySegementId" [value]="segment">
                    {{ segment.NomineeIndustrySegment }}
                      </mat-option>

                    
                  </mat-select>
                </mat-form-field>
              </ng-container>

            </td>
          </tr>
        </table>
        <div>
          <label class="QADMediumFieldLabel" style="padding-left: 50px; width: 328px; padding-top: 10px;">Rationale for Nomination</label>
          <div style="padding-left: 50px; padding-right: 0px; width: 328px">
            <textarea class="createformtextarea" id="rationalfornominationTextarea" maxlength="2000" [(ngModel)]="Rational" (keypress)="restrictSpecialChar($event)">
        </textarea>
          </div>
        </div>
      </div>

    </div>
    <div class="lbadditional">Additional Information for QAD Nomination</div>
    <div class="lbenterprise heldprior">Held Prior QAD Certification</div>
    <span class="tbenterprise" id="HeldPriorCert" [ngStyle]="{ 'font-weight': (HeldPriorCert == 'No' || HeldPriorCert == 'Yes') ? 'normal' : 'bold',
    'position': (HeldPriorCert !== 'No' && HeldPriorCert !== 'Yes') ? 'relative' : 'static',
    'top': (HeldPriorCert !== 'No' && HeldPriorCert !== 'Yes') ? '33px' : '0' }">{{HeldPriorCert}}</span>
  </div>
  <div class="createnewformbuttonrow" style="margin-left: 230px">

    <button id="Cancel" type="button" class="cancel" (click)="closeVerticallyCentered()">Cancel</button>
    <button id="CreateQAD" class="createQADButton" type="button" (click)="createQAD()">Create QAD Form</button>
    <!-- <button id="CreateEQAD" class="createEQADButton" type="button" >Create EQAD Form</button> -->
    <app-eqadvalidationform [NomineeEnterpriseId]="NomineesEnterprise" [NominatorPeopleKey]="NominatorPeopleKey" [PeopleKeyQRL]="PeopleKeyQRL" [RationaleNotes]="Rational" [HeldPriorCert]="HeldPriorCert" [MUIndustrySegementId]="MUIndustrySegementId"></app-eqadvalidationform>

  </div>
</ng-template>
