import { Component } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { IHeaderSupportSection } from '../../../core/models/HeaderSection';
import { AppConfigService } from '../../../core/services/app-config.service';


@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.css'],
})
export class NotAuthorizedComponent {
  isNotAdmin = false;
  configData: any;
  supportSectionValues: IHeaderSupportSection[] = [];
  QADportalLink: any = '';
  applicationurl: any = '';
  QADqualityLink: any = '';
  constructor(public commonService: CommonService, private config: AppConfigService) {
    this.getAdminAccess();
    this.getSupportSectionDetails()
    this.configData = config.getConfig();
    this.applicationurl = this.configData.msal.auth.redirectUri;
  }

  getAdminAccess(): any {
    this.commonService.hasAdminAccess$.subscribe(async (result: any) => {
      this.isNotAdmin = await result;
      

    });
  }
  getSupportSectionDetails() {
    this.commonService.getHeaderSupportSection().subscribe((response) => {

      if (response.length > 0) {
        this.supportSectionValues = response;
        this.QADportalLink = this.supportSectionValues.filter(x => x.SupportDesc === 'QA Director Portal').map(x => x.SupportLinkDesc);
        this.QADqualityLink = this.supportSectionValues.filter(x => x.SupportDesc === 'Submit Questions').map(x => x.SupportLinkDesc);
      }
    });
  }
}


